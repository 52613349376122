import API from '@/assets/libraries/API_Requests'
import DatabaseHelper from '@/assets/libraries/Database'

const SpotifyAPI = {}

/*
{
    collaborative:false,
    description:"",
    external_urls: {
        spotify:"https://open.spotify.com/playlist/3M32iE2SYrjQGoOS6805s1"
    },
    href:"https://api.spotify.com/v1/playlists/3M32iE2SYrjQGoOS6805s1",
    id:"3M32iE2SYrjQGoOS6805s1",
    images: [
        {
            height:null,
            url:"https://i.scdn.co/image/ab67706c0000bebb28c556ec59cc7710b6f04052",
            width:null,
        }
    ],
    name:"eh eh aye im on vacation !",
    owner: {
        display_name:"christysimmons",
        external_urls: {
            "spotify:"https://open.spotify.com/user/christy_simmons"
        },
        spotify:"https://open.spotify.com/user/christy_simmons",
        href:"https://api.spotify.com/v1/users/christy_simmons",
        id:"christy_simmons",
        type:"user",
        uri:"spotify:user:christy_simmons",
    },
    primary_color:null,
    public:false,
    snapshot_id:"Mjc4LGFiMGVjNWEyYzZmNTM4NTc2M2JlMTMwZGNlMzU3ZTZkNmIzYjZkNmQ=",
    tracks: {
        href:"https://api.spotify.com/v1/playlists/3M32iE2SYrjQGoOS6805s1/tracks",
        total:122
    },
    type:"playlist",
    uri:"spotify:playlist:3M32iE2SYrjQGoOS6805s1",
}
*/

SpotifyAPI.getAllUsersPlaylists = function(user_id) {
    return new Promise((resolve, reject) => {
        let limit = 50;
        let total_playlists = 0;
        let playlists = [];

        // Vue.spotify.getUserPlaylists(user_id, {limit}, (error, response) => {
        API.CallSpotify('GET', `/users/${user_id}/playlists?offset=0&limit=${limit}`, null, function (response) {
            if (response.error != undefined) {
                console.log('user playlist error', response.error);
                return reject(response.error);
            }

            total_playlists = response.total;
            playlists.push(...response.items)

            let recursion_promises = [];
            for (let offset = limit; offset < total_playlists; offset += limit) { //Don't start at 0 since we already have one group
                recursion_promises.push(new Promise((recursion_resolve, recursion_reject) => {
                        API.CallSpotify('GET', `/users/${user_id}/playlists?offset=${offset}&limit=${limit}`, null, function (response) {
                            if (response.error != undefined) {
                                console.log('playlist song error', response.error);
                                return recursion_reject();
                            }

                            // console.log('playlists songs', response);
                            playlists.push(...response.items);
                            return recursion_resolve(response.items)
                        })
                }))
            }

            Promise.all(recursion_promises).then(() => {
                for (let i = 0; i < playlists.length; i++) {
                    playlists[i] = removeUnusedValuesFromObject(playlists[i], [
                        'description',
                        'id',
                        {parent_key: 'images', keep: ['url']},
                        'name',
                        {parent_key: 'owner', keep: [
                            'display_name',
                            {parent_key: 'external_urls', keep: ['spotify']},
                            'id',
                            'uri'
                        ]},
                        {parent_key: 'tracks', keep: ['total']},
                        'uri'
                    ])
                }

                return resolve(playlists)
            }).catch((error) => {
                return reject(error);
            })
        })
    });
}

/*
{
    added_at:"2020-02-16T18:33:23Z",
    added_by: {
        external_urls: {
            spotify:"https://open.spotify.com/user/christy_simmons"
        },
        spotify:"https://open.spotify.com/user/christy_simmons",
        href:"https://api.spotify.com/v1/users/christy_simmons",
        id:"christy_simmons",
        type:"user",
        uri:"spotify:user:christy_simmons",
    },
    is_local:false,
    primary_color:null,
    track:{
        album:{
            album_type:"single",
            artists: [
                {
                    external_urls: {
                        spotify:"https://open.spotify.com/artist/6GkJh85o22LfD2vgL9DP6f"
                    },
                    href:"https://api.spotify.com/v1/artists/6GkJh85o22LfD2vgL9DP6f",
                    id:"6GkJh85o22LfD2vgL9DP6f",
                    name:"Dirty Heads",
                    type:"artist",
                    uri:"spotify:artist:6GkJh85o22LfD2vgL9DP6f",
                }
            ]
            available_markets:Array[170], ['US', ...]
            external_urls: {
                spotify:"https://open.spotify.com/album/7xR5BJoQg0x0bHac8GMipk",
            },
            href:"https://api.spotify.com/v1/albums/7xR5BJoQg0x0bHac8GMipk",
            id:"7xR5BJoQg0x0bHac8GMipk",
            images: [
                {
                    height:640
                    url:"https://i.scdn.co/image/ab67616d0000b2739955b63c09b3d0622e755ddf"
                    width:640
                }
            ],
            name:"Vacation",
            release_date:"2017-06-30",
            release_date_precision:"day",
            total_tracks:1,
            type:"album",
            uri:"spotify:album:7xR5BJoQg0x0bHac8GMipk",
        },
        artists: [
            {
                external_urls: {
                    spotify:"https://open.spotify.com/artist/6GkJh85o22LfD2vgL9DP6f"
                },
                href:"https://api.spotify.com/v1/artists/6GkJh85o22LfD2vgL9DP6f",
                id:"6GkJh85o22LfD2vgL9DP6f",
                name:"Dirty Heads",
                type:"artist",
                uri:"spotify:artist:6GkJh85o22LfD2vgL9DP6f",
            }
        ],
        available_markets:Array[170], ['US', ...],
        disc_number:1,
        duration_ms:209142,
        episode:false,
        explicit:false,
        external_ids: {
            isrc:"USDPK1700118"
        },
        external_urls: {
            spotify:"https://open.spotify.com/track/7cdy4PbCdDZZNjyxoZyE0c"
        },
        href:"https://api.spotify.com/v1/tracks/7cdy4PbCdDZZNjyxoZyE0c",
        id:"7cdy4PbCdDZZNjyxoZyE0c",
        is_local:false,
        name:"Vacation",
        popularity:75,
        preview_url:"https://p.scdn.co/mp3-preview/bb0ede798b01af57eaf1c54260bf4fec89f14a04?cid=65505292a6f6485f83ac900af79817f7",
        track:true,
        track_number:1,
        type:"track",
        uri:"spotify:track:7cdy4PbCdDZZNjyxoZyE0c",
    },
    video_thumbnail: {
        url:null
    }
}
*/

// [
//     'added_at',
//     {parent_key: 'added_by', keep: [
//         {parent_key: 'external_urls', keep: ['spotify']},
//         'id',
//         'uri',
//     ]},
//     {parent_key: 'track', keep: [
//         {parent_key: 'album', keep: [
//             'album_type',
//             {parent_key: 'artists', keep: [
//                 {parent_key: 'external_urls', keep: ['spotify']},
//                 'id',
//                 'name',
//                 'uri',
//             ]},
//             {parent_key: 'external_urls', keep: ['spotify']},
//             'id',
//             {parent_key: 'images', keep: ['url']},
//             'name',
//             'release_date',
//             'release_date_precision',
//             'total_tracks',
//             'uri',
//         ]},
//         {parent_key: 'artists', keep: [
//             {parent_key: 'external_urls', keep: ['spotify']},
//             'id',
//             'name',
//             'uri',
//         ]},
//         'disc_number',
//         'duration_ms',
//         'explicit',
//         {parent_key: 'external_urls', keep: ['spotify']},
//         'id',
//         'name',
//         'popularity',
//         'track',
//         'track_number',
//         'uri',
//     ]}
// ]


SpotifyAPI.getAllSongsForPlaylist = function(playlist_id) {
    return new Promise((resolve, reject) => {
        let total_songs = 0;
        let songs_for_playlist = []
        let limit = 100;

        // API.CallSpotify('GET', `/playlists/${playlist_id}/tracks?fields=total`, null, function (response) {
        API.CallSpotify('GET', `/playlists/${playlist_id}/tracks?offset=0&limit=${limit}`, null, async function (response) {
            if (response.error != undefined) {
                console.log('playlist song error', response.error);
                return reject(response.error);
            }

            total_songs = response.total;

            // let stored_playlist_songs = window.localStorage.getItem(`songs_for_${playlist_id}`)
            let stored_playlist_songs = await DatabaseHelper.PlaylistManager.getPlaylist(playlist_id);
            console.log('stored_playlist_songs', typeof(stored_playlist_songs), stored_playlist_songs);
            if (stored_playlist_songs != null) {

                if (stored_playlist_songs.length == total_songs) {
                    stored_playlist_songs = stored_playlist_songs.filter((song) => song.track != undefined);
                    return resolve(stored_playlist_songs);
                }
            }

            songs_for_playlist.push(...response.items)

            let track_promises = [];
            for (let offset = limit; offset < total_songs; offset += limit) { //Don't start at 0 since we already have one group
                track_promises.push(new Promise((track_resolve, track_reject) => {
                        API.CallSpotify('GET', `/playlists/${playlist_id}/tracks?offset=${offset}&limit=${limit}`, null, function (response) {
                            if (response.error != undefined) {
                                console.log('playlist song error', response.error);
                                return track_reject();
                            }

                            // console.log('playlists songs', response);
                            songs_for_playlist.push(...response.items);
                            songs_for_playlist = songs_for_playlist.filter((song) => song.track != undefined);
                            return track_resolve(response.items)
                        })
                }))
            }

            Promise.all(track_promises).then(async () => {
                for (let i = 0; i < songs_for_playlist.length; i++) {
                    // console.log('song', songs_for_playlist[i])
                    songs_for_playlist[i] = removeUnusedValuesFromObject(songs_for_playlist[i], [
                        'added_at',
                        {parent_key: 'added_by', keep: [
                            {parent_key: 'external_urls', keep: ['spotify']},
                            'id',
                            'uri',
                        ]},
                        {parent_key: 'track', keep: [
                            {parent_key: 'album', keep: [
                                'album_type',
                                {parent_key: 'artists', keep: [
                                    {parent_key: 'external_urls', keep: ['spotify']},
                                    'id',
                                    'name',
                                    'uri',
                                ]},
                                {parent_key: 'external_urls', keep: ['spotify']},
                                'id',
                                {parent_key: 'images', keep: ['url']},
                                'name',
                                'release_date',
                                'release_date_precision',
                                'total_tracks',
                                'uri',
                            ]},
                            {parent_key: 'artists', keep: [
                                {parent_key: 'external_urls', keep: ['spotify']},
                                'id',
                                'name',
                                'uri',
                            ]},
                            'disc_number',
                            'duration_ms',
                            'explicit',
                            {parent_key: 'external_urls', keep: ['spotify']},
                            'id',
                            'name',
                            'popularity',
                            'track',
                            'track_number',
                            'uri',
                        ]}
                    ])
                }

                // window.localStorage.setItem(`songs_for_${playlist_id}`, JSON.stringify(songs_for_playlist))
                await DatabaseHelper.PlaylistManager.updatePlaylist(playlist_id, songs_for_playlist);
                return resolve(songs_for_playlist)
            }).catch((error) => {
                console.log(`Error in promises ${error}`)
                return reject(error);
            })
        })
    });
}

const one_day_in_seconds = (1000 * 60 * 60 * 24);
SpotifyAPI.getUserProfile = function(user_id) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {

        let stored_profile_info = await DatabaseHelper.ProfileManager.getProfile(user_id);
        // console.log('stored_profile_info', typeof(stored_profile_info), stored_profile_info);
        if (stored_profile_info != null) {

            let cached_time = stored_profile_info.cached_on
            if (cached_time != undefined) { 
                let today = new Date().getTime();
                let days_since_cached_time = Math.abs(cached_time - today) / one_day_in_seconds;
                
                if (days_since_cached_time <= 30) { //Update records after 30 days
                    return resolve(stored_profile_info);
                }
            }
        }

        API.CallSpotify('GET', `/users/${user_id}`, null, async function (response) {
            if (response.error != undefined) {
                console.log('user profile error', response.error);
                return reject();
            }

            await DatabaseHelper.ProfileManager.updateProfile(response);
            return resolve(response)
        })
    })
}

SpotifyAPI.getUserProfiles = function(user_ids) {
    return new Promise((resolve, reject) => {
        let users_promises = [];

        for (const user_id of user_ids) {
            users_promises.push(SpotifyAPI.getUserProfile(user_id))
        }

        Promise.all(users_promises).then((profiles) => {
            return resolve(profiles);
        }).catch((error) => {
            console.log(`Error in promises ${error}`)
            return reject();
        })
    })
}

export default SpotifyAPI

function removeUnusedValuesFromObject(object, keys_to_keep) {
    let new_object = {};
    for (const key_object of keys_to_keep) {
        // console.log('key_object', key_object, object)
        if (typeof(key_object) == 'object') {

            if (object[key_object.parent_key] == undefined) { continue; }

            if (Array.isArray(object[key_object.parent_key]) == true) { //Just check the array for the keep items
                new_object[key_object.parent_key] = []
                for (const element_of_array of object[key_object.parent_key]) {
                    new_object[key_object.parent_key].push(removeUnusedValuesFromObject(element_of_array, key_object.keep))
                }
            }else {
                new_object[key_object.parent_key] = removeUnusedValuesFromObject(object[key_object.parent_key], key_object.keep)
            }
        }else {
            let key = key_object;
            if (object[key] != undefined) {
                new_object[key] = object[key];
            }
        }
    }

    return new_object;
}