<template>
    <div class="login" style="background-color: #F8F9FA;">
        
        <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow">
            <h2 class="mt-6 text-center text-3xl font-bold text-gray-900">Sign in to a Spotify account to continue</h2>
            <!-- <p class="mt-2 text-center text-sm text-gray-600 max-w">
            Or
            <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
                start your 14-day free trial
            </a>
            </p> -->
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

                <div class="rounded-md bg-red-200 p-4 mb-4" v-show="show_error_logging_in_message">
                    <div class="ml-3">
                    <h3 class="text-sm font-medium text-red-800">
                        {{logging_in_error_message}}
                    </h3>
                    </div>
                </div>

                <div class="flex flex-col justify-center">
                    <button id="spotify_login_button" @click.stop.prevent="redirectToSpotify()" class="flex items-center w-full px-3 py-2 text-white rounded-lg text-lg shadow-md hover:shadow-lg hover:bg-green-700 transition ease-in-out" style="background-color: #1DB954;">
                        <!-- <img class="w-5" src="/src/assets/images/Spotify_Icon_RGB_Black.png"> -->
                        <img class="w-10 rounded-full" src="https://e7.pngegg.com/pngimages/392/305/png-clipart-spotify-music-playlist-streaming-media-social-network-miscellaneous-hand.png">
                        <span class="ml-6 font-medium">Login to Spotify</span>
                    </button>
                </div>

            <!-- <form class="space-y-6">

                <div class="rounded-md bg-red-200 p-4" v-show="show_error_logging_in_message">
                    <div class="ml-3">
                    <h3 class="text-sm font-medium text-red-800">
                        {{logging_in_error_message}}
                    </h3>
                    </div>
                </div>

                <div>
                <label for="email" class="block text-sm font-medium text-gray-700">
                    Email address or Phone Number
                </label>
                <div class="mt-1">
                    <input id="login_value" name="email" type="email" autocomplete="email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                </div>
                </div>

                <div>
                <label for="password" class="block text-sm font-medium text-gray-700">
                    Password
                </label>
                <div class="mt-1">
                    <input id="password_value" name="password" type="password" autocomplete="current-password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                </div>
                </div>

                <div class="flex items-center justify-end">

                <div class="text-sm">
                    <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
                    Forgot your password?
                    </a>
                </div>
                </div>

                <div>
                <button class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    @click.prevent="login()"
                    @keyup.enter="login()">
                    Sign in
                </button>
                </div>
            </form> -->

            <div class="mt-6" style="display: none;">
                <div class="relative">
                <div class="absolute inset-0 flex items-center">
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="relative flex justify-center text-sm">
                    <span class="px-2 bg-white text-gray-500">
                    Or continue with
                    </span>
                </div>
                </div>

                <div class="mt-6 grid grid-cols-3 gap-3">
                <div>
                    <a href="#" class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                    <span class="sr-only">Sign in with Facebook</span>
                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                        <path fill-rule="evenodd" d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z" clip-rule="evenodd" />
                    </svg>
                    </a>
                </div>

                <div>
                    <a href="#" class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                    <span class="sr-only">Sign in with Twitter</span>
                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                        <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                    </a>
                </div>

                <div>
                    <a href="#" class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                    <span class="sr-only">Sign in with GitHub</span>
                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                        <path fill-rule="evenodd" d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z" clip-rule="evenodd" />
                    </svg>
                    </a>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>

    </div>
</template>

<script>
import API from '@/assets/libraries/API_Requests'
import Cookies from '@/assets/libraries/basicFunctions'

export default {
    name: "Login",
    data() {
        return {
            show_error_logging_in_message: false,
            logging_in_error_message: undefined,

            parameters: {}
        }
    },
    methods: {
        // login: function() {
        //     console.log('login...')

        //     const login_value = document.querySelector('#login_value').value
        //     const password_value = document.querySelector('#password_value').value

        //     const search_only_admin = this.parameters.admin == 'true' ? true : false

        //     console.log('Values', login_value, password_value, 'search_only_admin', search_only_admin)
            
        //     let login_url = `${API.BASE_API_URL}/customers/login`
        //     if (search_only_admin == true) { login_url = `${API.BASE_API_URL}/customers/admin-login`; }

        //     API.Call('PUT', login_url, {login_value: login_value, password: password_value, search_only_admin: search_only_admin}, (response) => {
        //         console.log('response', response)
        //         if (response.error != undefined) {
        //             switch (response.title) {
        //                 case 'MISSING_PARAMETERS': this.logging_in_error_message = "Please enter a email address, phone number, and a password."; break;
        //                 default: this.logging_in_error_message = "The email, phone number, or password did not match any account."; break;
        //             }

        //             this.show_error_logging_in_message = true;
        //             return;
        //         }

        //         this.$data.account_info = response.user_data;
        //         this.$store.commit('loggedInUser', response.user_data)

        //         Cookies.createCookie('token', response.token, 30)

        //         if (response.user_data.is_admin == true) { Cookies.createCookie('should_be_admin', true, 30); }

        //         this.redirectToDestination();
        //     })
        // },
        validateLoggedInUser: function() {
            API.Call('GET', `https://api.spotify.com/v1/me`, null, (response) => {
                if (response.error != undefined) {
                    this.logging_in_error_message = "Error validating account with Spotify, please try again."
                    this.show_error_logging_in_message = true;
                    return
                }

                this.$data.account_info = response;
                this.$store.commit('loggedInUser', response)
                console.log('Logged in validated', response);
                this.redirectToDestination();
            })
        },

        redirectToSpotify: function() {
            let client_id = '65505292a6f6485f83ac900af79817f7'
            let redirect_uri = 'https://spotify.dawsonseibold.com/login/login_callback'; //'http:%2F%2Flocalhost:6065%2Flogin%2Flogin_callback' //'https:%2F%2Fspotify.dawsonseibold.com%2Flogin%2Flogin_callback'//'http:%2F%2Flocalhost:6065%2Flogin%2Flogin_callback' //'http:%2F%2Flocalhost:4000%2FsiteFiles%2Flogincallback.html'
            let redirect_uri_encoded = encodeURIComponent(redirect_uri)

            let scopes = [
                'user-read-private',
                'user-read-email',
                'playlist-read-private',
                'playlist-read-collaborative',
                'user-modify-playback-state',
                'user-read-playback-state',
            ]//'user-read-private%20user-read-email%20playlist-read-private%20playlist-read-collaborative%20user-modify-playback-state'
            let scopes_encoded = scopes.join('%20') //'user-read-private%20user-read-email%20playlist-read-private%20playlist-read-collaborative%20user-modify-playback-state'

            let url = `https://accounts.spotify.com/authorize?client_id=${client_id}&redirect_uri=${redirect_uri_encoded}&scope=${scopes_encoded}&response_type=token&state=123`
            window.open(url, '_blank');
        },

        redirectToDestination: function() {
            let search_params = new URLSearchParams(window.location.search);
            if (search_params.has('redirect')) {
                this.$router.push({ path: `${search_params.get('redirect')}` })
            }else {
                this.$router.push({ path: `/` })
            }
        },

        receivedPostMessage: function(event) {
            console.log('got postmessage', event);
            if (event.data == '') { return; }

            var hash = JSON.parse(event.data);
            if (hash.type == 'access_token') {

                // Auth.setAccessToken(hash.access_token, hash.expires_in || 60);
                // checkUser(true);
                Cookies.createCookie('access_token', hash.access_token, 30)
                this.validateLoggedInUser();
                this.spotify.setAccessToken(hash.access_token)
            }
        }
    },
    mounted() { //Loaded
        if (Cookies.readCookie('access_token') != null) { //Already logged in.
            this.redirectToDestination();
        }

        this.parameters = this.$route.query
        console.log(this.parameters)

        window.addEventListener('message', this.receivedPostMessage)
    },
    beforeDestroy () {
       window.removeEventListener('message', this.receivedPostMessage)
    }
}
</script>

<style scoped>
    #spotify_login_button:hover {
        background-color: #1a9946 !important;
    }
</style>