<template>
    <div class="job min-h-screen bg-gray-100" >

        <link href="//db.onlinewebfonts.com/c/82724711928097a9b800297e88bbfeb1?family=minecraft+enchantment" rel="stylesheet" type="text/css"/>

        <main class="py-10">

            <LoadingIndicator :active="stats_are_loading"  
                :is-full-page="false"></LoadingIndicator>

            <div class="max-w-6xl mx-auto px-6 grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div class="flex flex-col w-full col-span-1 sm:col-span-2">
                    <span class="font-semibold">Shuffle Play</span>
                    <span class="text-gray-600 text-sm">Shuffle play allows for a customized shuffle of your shared playlists.</span>
                </div>
                <div class="flex justify-end col-span-1">
                    <button type="button" class="w-full sm:w-auto inline-flex items-center justify-center px-4 py-2 space-x-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 bg-blue-600 hover:bg-blue-700"
                        @click.stop.prevent="quickQueue()">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13 10V3L4 14h7v7l9-11h-7z" />
                        </svg>
                        <span>Quick Queue</span>
                    </button>
                </div>
            </div>

            <div class="mt-8 max-w-6xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div class="space-y-6 lg:col-start-1 lg:col-span-2">

                    <section aria-labelledby="applicant-information-title">
                        <div class="bg-white shadow sm:rounded-lg">
                            <div class="px-4 py-5 sm:px-6 grid grid-cols-1 sm:grid-cols-4">
                                <div class="col-span-1 sm:col-span-3">
                                    <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">
                                        Playlists
                                    </h2>
                                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                                        Select a playlist to view its stats
                                    </p>
                                </div>
                                <div class="col-span-1 text-right">
                                    <button type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 bg-blue-600 hover:bg-blue-700"
                                        @click.stop.prevent="hide_playlist_selection_section = !hide_playlist_selection_section">
                                        {{hide_playlist_selection_section == true ? 'Expand' : 'Hide'}}
                                    </button>
                                </div>
                            </div>

                            <div class="border-t border-gray-200 px-4 py-5 sm:px-6 relative" style="min-height: 40px;" v-if="hide_playlist_selection_section == false">
                                <div class="block h-14" v-show="playlists_are_loading == true">
                                    <LoadingIndicator :active="playlists_are_loading"  
                                    :is-full-page="false"></LoadingIndicator>
                                </div>
                                

                                <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 overflow-scroll overscroll-contain h-64" v-if="playlists_are_loading == false">
                                    <!-- <div class="sm:col-span-1 bg-gray-200 shadow rounded-lg flex p-4 cursor-pointer transition ease-out hover:bg-gray-500 hover:text-white"
                                        :class="{'bg-gray-900 text-white': selected_playlist == playlist}"
                                        v-for="playlist in playlists" :key="playlist.id"
                                        @click.stop.prevent="selectPlaylist(playlist)">
                                        <span>{{playlist.name}}</span>
                                    </div> -->

                                    <PlaylistItem
                                        class="sm:col-span-1"
                                        v-for="playlist in playlists" :key="playlist.id"
                                        :info="playlist"
                                        :selected="selected_playlist == playlist"
                                        @click.stop.prevent="selectPlaylist(playlist)"
                                    />

                                </div>
                            </div>
                        </div>
                    </section>

                    <section aria-labelledby="applicant-information-title">
                        <div class="bg-white shadow sm:rounded-lg">
                            <div class="px-4 py-5 sm:px-6 grid grid-cols-1 sm:grid-cols-4">
                                <div class="col-span-1 sm:col-span-3">
                                    <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">
                                        Songs
                                    </h2>
                                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                                        Songs in the selected playlist
                                    </p>
                                </div>
                                <div class="col-span-1 text-right">
                                    <button type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 bg-blue-600 hover:bg-blue-700"
                                        @click.stop.prevent="hide_songs_section = !hide_songs_section">
                                        {{hide_songs_section == true ? 'Expand' : 'Hide'}}
                                    </button>
                                </div>
                            </div>

                            <div class="border-t border-gray-200 px-4 py-5 sm:px-6 relative" style="min-height: 40px;" v-if="hide_songs_section == false">
                                <div class="block h-14" v-show="songs_are_loading == true">
                                    <LoadingIndicator :active="songs_are_loading"  
                                    :is-full-page="false"></LoadingIndicator>
                                </div>
                                
                                <!-- :class="{'bg-gray-900 text-white': selected_playlist == playlist}" -->

                                <span class="text-gray-500 text-center w-full block" v-if="selected_playlist == undefined">No playlist is selected</span>
                                <span class="text-gray-500 text-center w-full block" v-if="songs_for_selected_playlist.length == 0 && selected_playlist != undefined">No songs are in the selected playlist</span>

                                <!-- overflow-scroll overscroll-contain -->
                                <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 h-64" style="overflow-y: auto;" v-if="songs_are_loading == false && selected_playlist != undefined && songs_for_selected_playlist.length > 0">
                                    <!-- <div class="sm:col-span-1 bg-gray-200 shadow rounded-lg flex flex-col p-4 cursor-pointer transition ease-out hover:bg-gray-500 hover:text-white"
                                        v-for="song in songs_for_selected_playlist" :key="song.id">
                                        <span>{{song.track.name}}</span>
                                        <span>{{song.added_by.id}}</span>
                                    </div> -->

                                    <!-- :item-size="32"  v-for="song in songs_for_selected_playlist"-->
                                    <!-- <RecycleScroller
                                        class="scroller"
                                        style="height: 100%;"
                                        :items="playlists"
                                        key-field="id"
                                        v-slot="{ song }"
                                        >
                                             <SongItem
                                                class="sm:col-span-1"
                                                :key="song.id"
                                                :info="song"
                                                :user_profiles="loaded_user_profiles"
                                            />
                                    </RecycleScroller> -->

                                    <SongItem
                                        class="sm:col-span-1"
                                        v-for="song in songs_for_selected_playlist" :key="song.id"
                                        :info="song"
                                        :user_profiles="loaded_user_profiles"
                                    />

                                </div>
                            </div>
                        </div>
                    </section>

                    <section aria-labelledby="applicant-information-title">
                        <div class="bg-white shadow sm:rounded-lg">
                            <div class="px-4 py-5 sm:px-6 grid grid-cols-1 sm:grid-cols-5">
                                <div class="col-span-1 sm:col-span-2">
                                    <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">
                                        Shuffled Songs
                                    </h2>
                                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                                        Shuffle 50 songs to be truly random
                                    </p>
                                </div>
                                <div class="mt-4 sm:mt-0 col-span-1 sm:col-span-3 flex items-center sm:justify-end space-x-5 overflow-auto md:overflow-visible">

                                    <div class="relative inline-block text-left">
                                        <div>
                                            <button type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 whitespace-nowrap bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" id="menu-button" aria-expanded="true" aria-haspopup="true"
                                                @click.stop="kobe_options_menu_is_open = !kobe_options_menu_is_open"
                                                @keydown.esc="kobe_options_menu_is_open = false">
                                                Kobe Options
                                                <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                                                </svg>
                                            </button>
                                        </div>

                                        <transition
                                            enter-active-class="transition ease-out duration-100"
                                            enter-class="transform opacity-0 scale-95"
                                            enter-to-class="transform opacity-100 scale-100"
                                            leave-active-class="transition ease-in duration-75"
                                            leave-class="transform opacity-100 scale-100"
                                            leave-to-class="transform opacity-0 scale-95"
                                            >
                                            <div class="origin-top-right absolute right-0 mt-2 w-56 z-10 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1"
                                                v-show="kobe_options_menu_is_open">
                                                <div class="py-1" role="none">
                                                    <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                                                    <a href="#" class="group flex items-center px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0"
                                                        @click.stop.prevent="shuffleDisturbed()"
                                                        :disabled="selected_playlist == undefined"
                                                        :class="{'bg-gray-100 text-gray-400 cursor-default': selected_playlist == undefined, 'text-gray-700': selected_playlist != undefined}">
                                                        <svg class="mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                            <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" />
                                                        </svg>
                                                        Shuffle Disturbed
                                                    </a>
                                                    <a href="#" class="group flex items-center px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1"
                                                        @click.stop.prevent="shuffleKobe()"
                                                        :disabled="selected_playlist == undefined"
                                                        :class="{'bg-gray-100 text-gray-400 cursor-default': selected_playlist == undefined, 'text-gray-700': selected_playlist != undefined}">
                                                        <svg class="mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                                                            <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
                                                        </svg>
                                                        Shuffle Kobe
                                                    </a>
                                                    <a href="#" class="group flex items-center px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1"
                                                        @click.stop.prevent="shuffleLukeBryan()"
                                                        :disabled="selected_playlist == undefined"
                                                        :class="{'bg-gray-100 text-gray-400 cursor-default': selected_playlist == undefined, 'text-gray-700': selected_playlist != undefined}">
                                                        <svg class="mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                                                            <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
                                                        </svg>
                                                        Shuffle Luke Bryan
                                                    </a>
                                                </div>
                                            </div>
                                        </transition>
                                    </div>




                                    <button type="button" class="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                        @click.stop.prevent="shuffleSongs()"
                                        :disabled="selected_playlist == undefined"
                                        :class="{'bg-gray-600 cursor-default': selected_playlist == undefined, 'bg-blue-600 hover:bg-blue-700': selected_playlist != undefined}">
                                        Shuffle Songs
                                    </button>
                                    <!-- <button type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                        @click.stop.prevent="shuffleDisturbed()"
                                        :disabled="selected_playlist == undefined"
                                        :class="{'bg-gray-600 cursor-default': selected_playlist == undefined, 'bg-blue-600 hover:bg-blue-700': selected_playlist != undefined}">
                                        Shuffle Disturbed
                                    </button>
                                    <button type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                        @click.stop.prevent="shuffleKobe()"
                                        :disabled="selected_playlist == undefined"
                                        :class="{'bg-gray-600 cursor-default': selected_playlist == undefined, 'bg-blue-600 hover:bg-blue-700': selected_playlist != undefined}">
                                        Shuffle Kobe
                                    </button>
                                    <button type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                        @click.stop.prevent="shuffleLukeBryan()"
                                        :disabled="selected_playlist == undefined"
                                        :class="{'bg-gray-600 cursor-default': selected_playlist == undefined, 'bg-blue-600 hover:bg-blue-700': selected_playlist != undefined}">
                                        Shuffle Luke Bryan
                                    </button> -->
                                    <button type="button" class="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                        @click.stop.prevent="playShuffledSongs()"
                                        :disabled="selected_playlist == undefined || shuffled_songs.length == 0 || playing_shuffed_songs == true"
                                        :class="{'bg-gray-600 cursor-default': selected_playlist == undefined || shuffled_songs.length == 0 || playing_shuffed_songs == true, 'bg-red-600 hover:bg-red-700': selected_playlist != undefined && shuffled_songs.length > 0 && playing_shuffed_songs == false}">
                                        Queue & Play
                                    </button>
                                                                     
                                </div>
                            </div>

                            <div class="border-t border-gray-200 px-4 py-5 sm:px-6 relative" style="min-height: 40px;">
                                <div class="block h-14" v-show="shuffled_songs_are_loading == true">
                                    <LoadingIndicator :active="shuffled_songs_are_loading"  
                                    :is-full-page="false"></LoadingIndicator>
                                </div>

                                <span class="text-gray-500 text-center w-full block" v-if="selected_playlist == undefined">No playlist is selected</span>
                                <span class="text-gray-500 text-center w-full block" v-if="shuffled_songs.length == 0 && selected_playlist != undefined">Click the shuffle songs button to shuffle</span>

                                <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 overflow-scroll overscroll-contain h-64" v-if="shuffled_songs_are_loading == false && selected_playlist != undefined && shuffled_songs.length > 0">
                                    <!-- <div class="sm:col-span-1 bg-gray-200 shadow rounded-lg flex flex-col p-4 cursor-pointer transition ease-out hover:bg-gray-500 hover:text-white"
                                        v-for="song in shuffled_songs" :key="song.id">
                                        <span>{{song.track.name}}</span>
                                        <span>{{song.added_by.id}}</span>
                                    </div> -->

                                    <SongItem
                                        class="sm:col-span-1"
                                        v-for="song in shuffled_songs" :key="song.id"
                                        :info="song"
                                        :user_profiles="loaded_user_profiles"
                                        :obfuscate_song_title="true"
                                    />

                                </div>
                            </div>
                        </div>
                    </section>

                    <!-- <section aria-labelledby="applicant-information-title">
                        <div class="bg-white shadow sm:rounded-lg">
                            <div class="px-4 py-5 sm:px-6">
                                <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">Used Materials</h2>
                                <p class="mt-1 max-w-2xl text-sm text-gray-500">Parts / materials used for this job.</p>
                            </div>
                            <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                                <span class="text-gray-500">No parts used</span>
                            </div>
                        </div>
                    </section>

                    <section aria-labelledby="applicant-information-title">
                        <div class="bg-white shadow sm:rounded-lg">
                            <div class="px-4 py-5 sm:px-6">
                                <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">Invoice / Payment</h2>
                                <p class="mt-1 max-w-2xl text-sm text-gray-500">Invoice / payment info for this job.</p>
                            </div>
                            <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                                <span class="text-gray-500">No invoice generated yet.</span>
                            </div>
                        </div>
                    </section> -->
                </div>

                <section aria-labelledby="timeline-title" class="lg:col-start-3 lg:col-span-1 space-y-6">
                    <!-- <LoadingIndicator :active.sync="job_timeline_is_loading"  
                        :is-full-page="false"></LoadingIndicator> -->

                    <div class="">
                        <AlbumComponent
                            :info="selected_playlist"
                        />

                    </div>

                    <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                        <h2 id="timeline-title" class="text-lg font-medium text-gray-900">Shuffle Options</h2>

                        <div class="grid grid-cols-1 gap-y-8 mt-3">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500 flex flex-col">
                                    Selected Collaborators
                                    <span class="text-gray-400 text-xs">Select the collaborators that should be included in the shuffle.</span>
                                </dt>
                                <!-- <dd class="mt-1 text-sm text-gray-900 flex flex-col space-y-2">
                                    <button type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                        @click.stop.prevent="changeSelectedOptionCombo('all')"
                                        :class="selected_options_combo == 'all' ? 'bg-blue-800 border-white shadow-md' : 'bg-blue-600 hover:bg-blue-700'">
                                        All
                                    </button>
                                    <button type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                        @click.stop.prevent="changeSelectedOptionCombo('dawson_kobe')"
                                        :class="selected_options_combo == 'dawson_kobe' ? 'bg-blue-800 border-white shadow-md' : 'bg-blue-600 hover:bg-blue-700'">
                                        Dawson & Kobe
                                    </button>
                                    <button type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                        @click.stop.prevent="changeSelectedOptionCombo('dawson_tyren')"
                                        :class="selected_options_combo == 'dawson_tyren' ? 'bg-blue-800 border-white shadow-md' : 'bg-blue-600 hover:bg-blue-700'">
                                        Dawson & Tyren
                                    </button>
                                    <button type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                        @click.stop.prevent="changeSelectedOptionCombo('kobe_tyren')"
                                        :class="selected_options_combo == 'kobe_tyren' ? 'bg-blue-800 border-white shadow-md' : 'bg-blue-600 hover:bg-blue-700'">
                                        Kobe & Tyren
                                    </button>
                                </dd> -->

                                <dd class="mt-3 text-sm text-gray-900 flex flex-col space-y-2">
                                    <fieldset class="space-y-5">
                                        <div class="relative flex items-start cursor-pointer"
                                            v-for="collaborated_user in collaborated_user_ids_for_selected_playlist" :key="collaborated_user.user_id">
                                            <div class="flex items-center h-6">
                                                <input :id="collaborated_user.user_id" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" @change="changeShuffleOptionsSelectedColaborators(collaborated_user.user_id, $event.target.checked)" :checked="options.only_include_songs_from_users.includes(collaborated_user.user_id) == true">
                                            </div>
                                            <div class="ml-3 text-sm cursor-pointer">
                                                <label :for="collaborated_user.user_id" class="font-medium text-gray-700 space-x-2 flex justify-center items-center cursor-pointer">
                                                    <div class="flex justify-center items-center">
                                                        <img class="h-6 w-6 rounded-full" v-if="loaded_user_profiles != undefined && loaded_user_profiles[collaborated_user.user_id] != undefined && loaded_user_profiles[collaborated_user.user_id].images != undefined && loaded_user_profiles[collaborated_user.user_id].images.length > 0 && loaded_user_profiles[collaborated_user.user_id].images[0].url != undefined" :src="loaded_user_profiles[collaborated_user.user_id].images[0].url">
                                                        <span v-else class="h-6 w-6 rounded-full overflow-hidden bg-pink-600 text-white flex justify-center items-center">{{collaborated_user.user_id.substring(0,1).toUpperCase()}}</span>
                                                    </div>
                                                    <span v-if="loaded_user_profiles[collaborated_user.user_id] != undefined && loaded_user_profiles[collaborated_user.user_id].display_name != undefined">{{loaded_user_profiles[collaborated_user.user_id].display_name}}</span>
                                                    <span class="text-gray-500 text-xs truncate font-light">{{collaborated_user.user_id}}</span>
                                                </label>
                                                <p class="text-gray-500 text-sm">{{collaborated_user.number_of_songs_added}} {{ collaborated_user.number_of_songs_added | pluralize('song') }}</p>
                                            </div>
                                        </div>
                                    </fieldset>
                                </dd>

                            </div>
                        </div>

                    </div>

                    <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                        <h2 id="timeline-title" class="text-lg font-medium text-gray-900">Stats</h2>

                        <div class="flex justify-center mt-5" v-if="selected_playlist == undefined">
                            <span class="text-gray-500">No playlist selected</span>
                        </div>

                        <div class="grid grid-cols-1 gap-y-8 mt-3" v-if="shuffled_songs.length > 0">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500 flex flex-col">
                                    Shuffled Songs
                                    <span class="text-gray-400 text-xs">Number of songs per user</span>
                                </dt>
                                <dd class="mt-1 text-sm text-gray-900 flex flex-col">
                                    <span class="flex justify-between w-full mt-3 rounded-lg px-3 py-2 hover:bg-gray-100"
                                        v-for="user_stats in users_added_songs" :key="user_stats.user">
                                        <span v-if="loaded_user_profiles[user_stats.user] != undefined">
                                            <a :href="loaded_user_profiles[user_stats.user].external_urls.spotify" class="flex">
                                                <!-- <img class="h-6 w-6 rounded-full" :src="loaded_user_profiles[user_stats.user].images.length > 0 ? loaded_user_profiles[user_stats.user].images[0].url : undefined" alt=""> -->
                                                <div class="flex justify-center items-center">
                                                    <img class="h-6 w-6 rounded-full" v-if="loaded_user_profiles != undefined && loaded_user_profiles[user_stats.user] != undefined && loaded_user_profiles[user_stats.user].images != undefined && loaded_user_profiles[user_stats.user].images.length > 0 && loaded_user_profiles[user_stats.user].images[0].url != undefined" :src="loaded_user_profiles[user_stats.user].images[0].url">
                                                    <span v-else class="h-6 w-6 rounded-full overflow-hidden bg-pink-600 text-white flex justify-center items-center">{{user_stats.user.substring(0,1).toUpperCase()}}</span>
                                                </div>

                                                <span class="text-gray-800 font-medium ml-3">{{loaded_user_profiles[user_stats.user].display_name}}</span>
                                            </a>
                                        </span>
                                        <span v-else>{{user_stats.user}}</span>

                                        <span>{{user_stats.number_of_songs}} Songs</span>
                                    </span>
                                </dd>
                            </div>
                        </div>

                    </div>

                    <NowPlaying
                        :full-screen="false"
                        :user_profiles="loaded_user_profiles"
                    />

                    <!-- <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 mt-6">
                        <h2 id="timeline-title" class="text-lg font-medium text-gray-900">Timeline</h2>

                        <div class="flex justify-center mt-5" v-show="job_timeline == undefined || job_timeline.length == 0">
                            <span class="text-gray-500">No status changes yet</span>
                        </div>

                    </div> -->


                    <!-- <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                        <div class="flex flex-col justify-stretch space-y-3">
                            <button type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                @click.stop.prevent="rescheduleJob()"
                                :disabled="can_reschedule_job == false"
                                :class="{'bg-gray-600 cursor-default': can_reschedule_job == false, 'bg-blue-600 hover:bg-blue-700': can_reschedule_job == true}">
                                Reschedule Job
                            </button>

                            <button type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                @click.stop.prevent="cancel_job_modal_is_shown = true"
                                :disabled="can_cancel_job == false"
                                :class="{'bg-gray-600 cursor-default': can_cancel_job == false, 'bg-red-600 hover:bg-red-700': can_cancel_job == true}">
                                Cancel Job
                            </button>

                            <button type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                @click.stop.prevent="reportIssueWithJob()"
                                v-if="is_admin == false"
                                :disabled="can_report_issue_with_job == false"
                                :class="{'bg-gray-600 cursor-default': can_report_issue_with_job == false, 'bg-blue-600 hover:bg-blue-700': can_report_issue_with_job == true}">
                                Report Issue with Job
                            </button>

                            <button type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                @click.stop.prevent="markTechEnRoute()"
                                v-if="job.completed == false && job.canceled == false && is_admin == true"
                                :class="{'bg-gray-600 cursor-default': job.completed == true, 'bg-purple-600 hover:bg-purple-700': job.completed == false}">
                                Mark Tech En Route
                            </button>

                            <button type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                @click.stop.prevent="completed_job_modal_is_shown = true"
                                v-if="is_admin == true"
                                :disabled="job.completed == true"
                                :class="{'bg-gray-600 cursor-default': job.completed == true, 'bg-green-600 hover:bg-green-700': job.completed == false}">
                                Completed Job
                            </button>

                            <a class="group inline-flex items-start text-sm space-x-2 text-gray-500 hover:text-gray-900">
                                <svg class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                </svg>
                                <span>Job can be modified up to 24 hours before scheduled time.</span>
                            </a>

                            <a class="group inline-flex items-start text-sm space-x-2 text-gray-500 hover:text-gray-900">
                                <svg class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                </svg>
                                <span>Any issue with a job can be reported after the job has been marked complete. If we missed something, please let us know ASAP.</span>
                            </a>
                        </div>
                    </div> -->



                </section>

            </div>
        </main>

    </div>    
</template>






<script>
import API from '@/assets/libraries/API_Requests'
import Cookies from '@/assets/libraries/basicFunctions'
import SpotifyAPI from '@/assets/libraries/SpotifyAPI'
import ShuffleHandler from '@/assets/libraries/ShuffleHandler.js'

import LoadingIndicator from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
// import func from '../../vue-temp/vue-editor-bridge';

import NowPlaying from "@/components/NowPlaying";
import AlbumComponent from "@/components/AlbumComponent";
import PlaylistItem from "@/components/PlaylistItem";
import SongItem from "@/components/SongItem";

export default {
    name: 'Shuffle',
    components: { LoadingIndicator, NowPlaying, AlbumComponent, PlaylistItem, SongItem },
    data() {
        return {
            stats_are_loading: false,
            parameters: [],

            playlists: [],
            selected_playlist: undefined,
            playlists_are_loading: false,

            songs_for_selected_playlist: [],
            track_id_for_song_index: {},
            collaborated_user_ids_for_selected_playlist: [],
            songs_are_loading: false,

            shuffled_songs: [],
            shuffled_songs_are_loading: false,
            playing_shuffed_songs: false,

            loaded_user_profiles: {},

            hide_playlist_selection_section: false,
            hide_songs_section: false,

            selected_geners: [],

            options: JSON.parse(JSON.stringify(ShuffleHandler.options)),
            selected_options_combo: 'all',

            kobe_options_menu_is_open: false,

        }
    },
    computed: {
        users_added_songs: function() {
            let users_songs = {}

            for (let song of this.shuffled_songs) {
                if (users_songs[song.added_by.id] == undefined) {
                    users_songs[song.added_by.id] = 1
                }else {
                    users_songs[song.added_by.id] += 1;
                }
            }
            console.log('users_added_songs', users_songs);

            let users = Object.keys(users_songs).map((user) => { return { user:user, number_of_songs: users_songs[user] } });

            users.sort((a, b) => {
                return b.number_of_songs - a.number_of_songs
            })

            this.getUsersProfiles(Object.keys(users_songs))

            return users;
        },

    },
    methods: {
        getPlaylists: function() {
            return new Promise((resolve, reject) => {
                this.playlists_are_loading = true
                let user_id = this.$store.state.account_info.id

                SpotifyAPI.getAllUsersPlaylists(user_id).then((playlists) => {
                    this.playlists_are_loading = false
                    this.playlists = playlists

                    return resolve(playlists);
                }).catch((error) => {
                    this.playlists_are_loading = false
                    return reject(error);
                })  
            });
        },

        getSongsForPlaylist: function(playlist_id) {
            return new Promise((resolve, reject) => {
                this.songs_are_loading = true
                this.shuffled_songs = [];

                SpotifyAPI.getAllSongsForPlaylist(playlist_id).then((songs) => {
                    this.songs_are_loading = false
                    this.songs_for_selected_playlist = songs

                    let user_ids = {};

                    this.track_id_for_song_index = {};
                    for (const [index, song] of this.songs_for_selected_playlist.entries()) {
                        if (song?.track?.album == undefined) { console.log('missing album info', index, song); }
                        this.track_id_for_song_index[song.id] = index;

                        if (user_ids[song.added_by.id] == undefined) { user_ids[song.added_by.id] = { user_id: song.added_by.id, number_of_songs_added: 0 }; }
                        user_ids[song.added_by.id].number_of_songs_added++;
                    }

                    this.collaborated_user_ids_for_selected_playlist = Object.values(user_ids);
                    console.log('collaborated_user_ids_for_selected_playlist', this.collaborated_user_ids_for_selected_playlist)

                    return resolve(songs);
                }).catch((error) => {
                    this.songs_are_loading = false
                    return reject(error);
                })  
            });
        },

        getUsersProfiles: function(user_ids) {
            return new Promise((resolve, reject) => {
                SpotifyAPI.getUserProfiles(user_ids).then((profiles) => {
                    for (const profile of profiles) {
                        this.$set(this.loaded_user_profiles, profile.id, profile) //Forced UI to update with change
                    }

                    return resolve(profiles);
                }).catch((error) => {
                    return reject(error);
                })  
            })
        },

        selectPlaylist: async function(playlist) {
            this.selected_playlist = playlist
            this.playing_shuffed_songs = false
            await this.getSongsForPlaylist(playlist.id)
        },

        shuffleSongs: function() {
            // let shuffled = JSON.parse(JSON.stringify(this.songs_for_selected_playlist)) //The JSON parse and stringify will remove the relationship between the new shuffled array and the origianl array. With out this, reordering the shuffled array would reorder the master array
            // // console.log('starting array', JSON.parse(JSON.stringify(this.songs_for_selected_playlist)).map((e) => e.track.name))
            // shuffled = shuffled.shuffle();
            // // console.log('shuffled', shuffled.map((e) => e.track.name))
            // shuffled.splice(50, shuffled.length - 50) //Remove all but the first 50 songs

            let songs_to_shuffle = JSON.parse(JSON.stringify(this.songs_for_selected_playlist)) //The JSON parse and stringify will remove the relationship between the new shuffled array and the origianl array. With out this, reordering the shuffled array would reorder the master array
            let shuffled = ShuffleHandler.shuffleUsingOptions(songs_to_shuffle, this.options);

            this.shuffled_songs = shuffled
            this.playing_shuffed_songs = false

            console.log(this.shuffled_songs)
        },

        shuffleDisturbed: function() {
            let shuffled = JSON.parse(JSON.stringify(this.songs_for_selected_playlist))
            shuffled = shuffled.shuffle();

            let disturbed_songs = shuffled.filter((song) => {
                for(const artist of song.track.artists){
                    if(artist.name == "Disturbed"){ return true; }
                }
                return false;
            })

            this.shuffled_songs = disturbed_songs
            this.playing_shuffed_songs = false
        },

        shuffleLukeBryan: function() {
            let shuffled = JSON.parse(JSON.stringify(this.songs_for_selected_playlist))
            shuffled = shuffled.shuffle();

            let luke_songs = shuffled.filter((song) => {

                for(const artist of song.track.artists){
                    if(artist.name == "Luke Bryan"){ return true; }
                }
                return false;
            })

            this.shuffled_songs = luke_songs
            this.playing_shuffed_songs = false
        },

        shuffleKobe: function() {
             let shuffled = JSON.parse(JSON.stringify(this.songs_for_selected_playlist))
             shuffled = shuffled.shuffle();
            //  console.log('shuffled', shuffled.map((e) => e.track.name))

            let kobe_songs = shuffled.filter((song) => {
                // console.log(song);
                // console.log(song.added_by.id);

                if(song.added_by.id == "koberson1"){ return true; }
                return false;
            })

            // console.log(kobe_songs);

            this.shuffled_songs = kobe_songs
            this.playing_shuffed_songs = false
        },
        playShuffledSongs: function() {
            this.playing_shuffed_songs = true

            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve) => {
                let uris = this.shuffled_songs.map((song) => { return song.track.uri; })
                uris.push('spotify:track:3EqFNWt1k2kb5RSCTPQ9Hx'); //Air siren or Horn Signal
                // uris.push('spotify:track:1A5V1sxyCLpKJezp75tUXn'); //Closing Time
                // uris.push('spotify:track:2y4lAQpi5VTNLu2ldeTdUH'); //We Can't Stop
                // uris.push('spotify:track:0K2WjMLZYr09LKwurGRYRE'); //New York, New York
                // uris.push('spotify:track:7GhIk7Il098yCjg4BQjzvb'); //Never Gonna Give You Up.
                // uris.push('spotify:track:1D33HYMtMhZ9KvBdgOHZEU'); //Clean Up - Barney 

                await this.togglePlaybackShuffle(false).catch((error) => { console.log('Error settings users playback shuffle state', error); })

                API.CallSpotify('PUT', `/me/player/play`, {uris}, function () {
                    return resolve();
                })
            });
        },

        togglePlaybackShuffle: function(state = false) { //state = true: shuffle users playback, false: do not shuffle
            return new Promise((resolve, reject) => {
                API.CallSpotify('PUT', `/me/player/shuffle?state=${state}`, { }, function (response) {
                    console.log('toggle playback shuffle state', state, response)
                    if (response?.error != undefined) { return reject(response.error); }
                    return resolve();
                })
            });
        },

        changeSelectedOptionCombo: function(combo) {
            this.selected_options_combo = combo;

            switch (combo) {
                case 'dawson_kobe': this.options.exclude_songs_from_users = ['drunkensalor23']; break;
                case 'dawson_tyren': this.options.exclude_songs_from_users = ['koberson1']; break;
                case 'kobe_tyren': this.options.exclude_songs_from_users = ['dawson_seibold']; break;
                case 'all': //Fall through
                default: this.options.exclude_songs_from_users = []; break;
            }
        },
        changeShuffleOptionsSelectedColaborators: function(collaborators_user_id, selected_user) {
            console.log('collaborators_user_id', collaborators_user_id, selected_user);

            if (selected_user == true) {
                if (this.options.only_include_songs_from_users.includes(collaborators_user_id) == true)  { return; }
                this.options.only_include_songs_from_users.push(collaborators_user_id);
            }else {
                let user_id_index = this.options.only_include_songs_from_users.indexOf(collaborators_user_id);
                if (user_id_index != -1) {
                    this.options.only_include_songs_from_users.splice(user_id_index, 1); //Delete 1 element
                }
            }
        },

        quickQueue: async function() {
            let playlist = this.playlists.filter((playlist) => playlist.id == '1iZwQHiG2a4jQFKiR1MSay')[0]; //The Lit Shit Mothafucka Playlist
            if (playlist == undefined) { return; }
            await this.selectPlaylist(playlist)
            console.log('playlist loaded');
            await this.shuffleSongs();
            await this.playShuffledSongs();
        }
    },
    async mounted() {
        console.log('Job detail view', this.$route)
        // this.job_id = this.$route.params.job_id;

        this.parameters = this.$route.query
        // console.log(this.parameters)

        // await this.getJobData();
        await this.spotify.setAccessToken(Cookies.readCookie('access_token'))
        await this.getPlaylists();

        this.options.only_include_songs_from_users = ["dawson_seibold", "koberson1"]
    }
}
</script>

<style scoped>
    @import url(//db.onlinewebfonts.com/c/82724711928097a9b800297e88bbfeb1?family=minecraft+enchantment);

    @font-face {font-family: "minecraft enchantment"; src: url("//db.onlinewebfonts.com/t/82724711928097a9b800297e88bbfeb1.eot"); src: url("//db.onlinewebfonts.com/t/82724711928097a9b800297e88bbfeb1.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/82724711928097a9b800297e88bbfeb1.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/82724711928097a9b800297e88bbfeb1.woff") format("woff"), url("//db.onlinewebfonts.com/t/82724711928097a9b800297e88bbfeb1.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/82724711928097a9b800297e88bbfeb1.svg#minecraft enchantment") format("svg"); }
</style>