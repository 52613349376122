<template>
    <div class="job min-h-screen bg-gray-100" >
        <main class="py-10">

            <LoadingIndicator :active="stats_are_loading"  
                :is-full-page="false"></LoadingIndicator>

            <div class="mt-8 max-w-6xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div class="space-y-6 lg:col-start-1 lg:col-span-2">
                    <section aria-labelledby="applicant-information-title">
                        <div class="bg-white shadow sm:rounded-lg">
                            <div class="px-4 py-5 sm:px-6">
                                <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">
                                    Playlists
                                </h2>
                                <p class="mt-1 max-w-2xl text-sm text-gray-500">
                                    Select a playlist to view its stats
                                </p>
                            </div>

                            <div class="border-t border-gray-200 px-4 py-5 sm:px-6 relative" style="min-height: 40px;">
                                <div class="block h-14" v-show="playlists_are_loading == true">
                                    <LoadingIndicator :active="playlists_are_loading"  
                                    :is-full-page="false"></LoadingIndicator>
                                </div>
                                

                                <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 overflow-scroll overscroll-contain h-64" v-if="playlists_are_loading == false">
                                    <!-- <div class="sm:col-span-1 bg-gray-200 shadow rounded-lg flex p-4 cursor-pointer transition ease-out hover:bg-gray-500 hover:text-white"
                                        :class="{'bg-gray-900 text-white': selected_playlist == playlist}"
                                        v-for="playlist in playlists" :key="playlist.id"
                                        @click.stop.prevent="selectPlaylist(playlist)">
                                        <span>{{playlist.name}}</span>
                                    </div> -->

                                    <PlaylistItem
                                        class="sm:col-span-1"
                                        v-for="playlist in playlists" :key="playlist.id"
                                        :info="playlist"
                                        :selected="selected_playlist == playlist"
                                        @click.stop.prevent="selectPlaylist(playlist)"
                                    />

                                </div>
                            </div>


                            <!-- <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">
                                        Created on
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900">
                                        {{job.created_on | moment("dddd, MMMM Do YYYY")}}
                                    </dd>
                                </div>
                                <div class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">
                                        Assigned Technician
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900">
                                        <a :href="`/staff/${job.assigned_staff_UUID}`" class="font-medium text-gray-900" :content="job.assigned_tech.title" v-tippy>{{job.assigned_staff_UUID != undefined ? `${job.assigned_tech.first_name} ${job.assigned_tech.last_name}` : 'Unassigned'}}</a>
                                    </dd>
                                </div>

                                <div class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">
                                        Estimated Duration
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900">
                                        {{job.estimated_duration}} {{`hour${job.estimated_duration > 1 ? 's' : ''}`}}
                                    </dd>
                                </div>

                                <div class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">
                                        Customer will be present
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900">
                                        {{job.customer_will_be_present == true ? 'Yes' : 'No'}}
                                    </dd>
                                </div>


                                <div class="sm:col-span-1" v-if="job != undefined && job.job_type.short_name == 'other' && job.cost != undefined">
                                    <dt class="text-sm font-medium text-gray-500">
                                        Cost
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900">
                                        ${{job.cost}}
                                    </dd>
                                </div>

                                <div class="sm:col-span-1" v-if="job.original_job_id != undefined">
                                    <dt class="text-sm font-medium text-gray-500">
                                        Original Job
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900">
                                        <a :href="`/jobs/${job.original_job_id}`" class="font-medium text-gray-900">Job ID: {{job.original_job_id}}</a>
                                    </dd>
                                </div>
                            

                                <div class="sm:col-span-1">
                                    <dt class="text-sm font-medium text-gray-500">
                                        Service Location
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900 flex flex-col">
                                        <span>{{job.service_location.address_line_1}}</span>
                                        <span>{{job.service_location.address_line_2}}</span>
                                        <span>{{job.service_location.city}}, {{job.service_location.state}} {{job.service_location.postal_code}}</span>

                                        <div v-if="is_admin == true"><span class="inline-flex items-center px-2 py-0.5 mt-0 rounded text-sm font-medium" :class="`bg-${job.service_location.district.hex_color}-200 text-${job.service_location.district.hex_color}-800`" content="Service District" v-tippy>{{job.service_location.district.display_name}}</span></div>
                                    </dd>
                                </div>

                                <div class="sm:col-span-1" v-if="is_admin == true">
                                    <dt class="text-sm font-medium text-gray-500">
                                        Customer
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900 flex flex-col">
                                        <a :href="`/customers/${job.customer.UUID}`"><span>{{job.customer.first_name}} {{job.customer.last_name}}</span></a>
                                        <a :href="`tel:${job.customer.primary_phone_number}`"><span>Primary: {{job.customer.primary_phone_number | phone_number_formatter}}</span></a>
                                        <a :href="`tel:${job.customer.secondary_phone_number}`" v-if="job.customer.secondary_phone_number != undefined"><span>Secondary: {{job.customer.secondary_phone_number | phone_number_formatter}}</span></a>
                                        <div v-if="is_admin == true"><span class="inline-flex items-center px-2 py-0.5 mt-0 rounded text-sm font-medium" :class="`bg-purple-200 text-purple-800`" content="Customer Type / Plan" v-tippy>{{job.customer.customer_type.display_name}}</span></div>
                                    </dd>
                                </div>
                                

                            </dl>
                            </div> -->
                            <!-- <div>
                            <a href="#" class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">Read full application</a>
                            </div> -->
                        </div>
                    </section>

                    <section aria-labelledby="applicant-information-title">
                        <div class="bg-white shadow sm:rounded-lg">
                            <div class="px-4 py-5 sm:px-6">
                                <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">
                                    Songs
                                </h2>
                                <p class="mt-1 max-w-2xl text-sm text-gray-500">
                                    Songs in the selected playlist
                                </p>
                            </div>

                            <div class="border-t border-gray-200 px-4 py-5 sm:px-6 relative" style="min-height: 40px;">
                                <div class="block h-14" v-show="songs_are_loading == true">
                                    <LoadingIndicator :active="songs_are_loading"  
                                    :is-full-page="false"></LoadingIndicator>
                                </div>
                                
                                <!-- :class="{'bg-gray-900 text-white': selected_playlist == playlist}" -->

                                <span class="text-gray-500 text-center w-full block" v-if="selected_playlist == undefined">No playlist is selected</span>
                                <span class="text-gray-500 text-center w-full block" v-if="songs_for_selected_playlist.length == 0 && selected_playlist != undefined">No songs are in the selected playlist</span>

                                <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 overflow-scroll overscroll-contain h-64" v-if="songs_are_loading == false && selected_playlist != undefined && songs_for_selected_playlist.length > 0">
                                    <div class="sm:col-span-1 bg-gray-200 shadow rounded-lg flex flex-col p-4 cursor-pointer transition ease-out hover:bg-gray-500 hover:text-white"
                                        v-for="song in songs_for_selected_playlist" :key="song.id">
                                        <span>{{song.track.name}}</span>
                                        <span>{{song.added_by.id}}</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>

                    <section aria-labelledby="applicant-information-title" v-if="selected_playlist != undefined">
                        <div class="bg-white shadow sm:rounded-lg">
                            <div class="px-4 py-5 sm:px-6">
                                <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">
                                    Stats
                                </h2>
                                <p class="mt-1 max-w-2xl text-sm text-gray-500">
                                    Stats for the selected playlist
                                </p>
                            </div>

                            <!-- <div class="border-t border-gray-200 px-4 py-5 sm:px-6 relative" style="min-height: 40px;">
                                <div class="block h-14" v-show="playlists_are_loading == true">
                                    <LoadingIndicator :active="playlists_are_loading"  
                                    :is-full-page="false"></LoadingIndicator>
                                </div>
                                

                                <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 overflow-scroll overscroll-contain h-64" v-if="playlists_are_loading == false">
                                    <div class="sm:col-span-1 bg-gray-200 shadow rounded-lg flex p-4 cursor-pointer transition ease-out hover:bg-gray-500 hover:text-white"
                                        :class="{'bg-gray-900 text-white': selected_playlist == playlist}"
                                        v-for="playlist in playlists" :key="playlist.id"
                                        @click.stop.prevent="selectPlaylist(playlist)">
                                        <span>{{playlist.name}}</span>
                                    </div>

                                </div>
                            </div> -->


                            <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                                <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                    <!-- <div class="sm:col-span-1">
                                        <dt class="text-sm font-medium text-gray-500">
                                            Created on
                                        </dt>
                                        <dd class="mt-1 text-sm text-gray-900">
                                            {{job.created_on | moment("dddd, MMMM Do YYYY")}}
                                        </dd>
                                    </div> -->

                                    <!-- <div class="sm:col-span-1">
                                        <dt class="text-sm font-medium text-gray-500">
                                            Service Location
                                        </dt>
                                        <dd class="mt-1 text-sm text-gray-900 flex flex-col">
                                            <span>{{job.service_location.address_line_1}}</span>
                                            <span>{{job.service_location.address_line_2}}</span>
                                            <span>{{job.service_location.city}}, {{job.service_location.state}} {{job.service_location.postal_code}}</span>

                                            <div v-if="is_admin == true"><span class="inline-flex items-center px-2 py-0.5 mt-0 rounded text-sm font-medium" :class="`bg-${job.service_location.district.hex_color}-200 text-${job.service_location.district.hex_color}-800`" content="Service District" v-tippy>{{job.service_location.district.display_name}}</span></div>
                                        </dd>
                                    </div> -->

                                    <div class="sm:col-span-1">
                                        <dt class="text-sm font-medium text-gray-500">
                                            User Stats
                                        </dt>
                                        <dd class="mt-1 text-sm text-gray-900 flex flex-col">
                                            <span class="flex justify-between w-full mt-3 rounded-lg px-3 py-2 hover:bg-gray-100"
                                                v-for="user_stats in users_added_songs" :key="user_stats.user">
                                                <span v-if="loaded_user_profiles[user_stats.user] != undefined">
                                                    <a :href="loaded_user_profiles[user_stats.user].external_urls.spotify" class="flex">
                                                        <img class="h-6 w-6 rounded-full" :src="loaded_user_profiles[user_stats.user].images[0].url" alt="">
                                                        <span class="text-gray-800 font-medium ml-3">{{loaded_user_profiles[user_stats.user].display_name}}</span>
                                                    </a>
                                                </span>
                                                <span v-else>{{user_stats.user}}</span>

                                                <span>{{user_stats.number_of_songs}} Songs</span>
                                            </span>
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </section>

                    <!-- <section aria-labelledby="applicant-information-title">
                        <div class="bg-white shadow sm:rounded-lg">
                            <div class="px-4 py-5 sm:px-6">
                                <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">Used Materials</h2>
                                <p class="mt-1 max-w-2xl text-sm text-gray-500">Parts / materials used for this job.</p>
                            </div>
                            <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                                <span class="text-gray-500">No parts used</span>
                            </div>
                        </div>
                    </section>

                    <section aria-labelledby="applicant-information-title">
                        <div class="bg-white shadow sm:rounded-lg">
                            <div class="px-4 py-5 sm:px-6">
                                <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">Invoice / Payment</h2>
                                <p class="mt-1 max-w-2xl text-sm text-gray-500">Invoice / payment info for this job.</p>
                            </div>
                            <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                                <span class="text-gray-500">No invoice generated yet.</span>
                            </div>
                        </div>
                    </section> -->
                </div>

                <section aria-labelledby="timeline-title" class="lg:col-start-3 lg:col-span-1">
                    <!-- <LoadingIndicator :active.sync="job_timeline_is_loading"  
                        :is-full-page="false"></LoadingIndicator> -->

                    <!-- <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                        <h2 id="timeline-title" class="text-lg font-medium text-gray-900">Selected Playlist</h2>

                        <div class="flex justify-center mt-5" v-if="selected_playlist == undefined">
                            <span class="text-gray-500">No playlist selected</span>
                        </div>

                        <div class="mt-3 bg-gray-200 shadow rounded-lg flex p-4"
                            v-if="selected_playlist != undefined">
                            <span>{{selected_playlist.name}}</span>
                        </div>

                    </div> -->

                    <div>
                        <AlbumComponent
                            :info="selected_playlist"
                        />
                    </div>

                    <!-- <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 mt-6">
                        <h2 id="timeline-title" class="text-lg font-medium text-gray-900">Timeline</h2>

                        <div class="flex justify-center mt-5" v-show="job_timeline == undefined || job_timeline.length == 0">
                            <span class="text-gray-500">No status changes yet</span>
                        </div>

                    </div> -->


                    <div class="bg-white px-4 py-5 mt-6 shadow sm:rounded-lg sm:px-6">
                        <div class="flex flex-col justify-stretch space-y-3">
                            <!-- <button type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                @click.stop.prevent="rescheduleJob()"
                                :disabled="can_reschedule_job == false"
                                :class="{'bg-gray-600 cursor-default': can_reschedule_job == false, 'bg-blue-600 hover:bg-blue-700': can_reschedule_job == true}">
                                Reschedule Job
                            </button>

                            <button type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                @click.stop.prevent="cancel_job_modal_is_shown = true"
                                :disabled="can_cancel_job == false"
                                :class="{'bg-gray-600 cursor-default': can_cancel_job == false, 'bg-red-600 hover:bg-red-700': can_cancel_job == true}">
                                Cancel Job
                            </button>

                            <button type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                @click.stop.prevent="reportIssueWithJob()"
                                v-if="is_admin == false"
                                :disabled="can_report_issue_with_job == false"
                                :class="{'bg-gray-600 cursor-default': can_report_issue_with_job == false, 'bg-blue-600 hover:bg-blue-700': can_report_issue_with_job == true}">
                                Report Issue with Job
                            </button>

                            <button type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                @click.stop.prevent="markTechEnRoute()"
                                v-if="job.completed == false && job.canceled == false && is_admin == true"
                                :class="{'bg-gray-600 cursor-default': job.completed == true, 'bg-purple-600 hover:bg-purple-700': job.completed == false}">
                                Mark Tech En Route
                            </button>

                            <button type="button" class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                @click.stop.prevent="completed_job_modal_is_shown = true"
                                v-if="is_admin == true"
                                :disabled="job.completed == true"
                                :class="{'bg-gray-600 cursor-default': job.completed == true, 'bg-green-600 hover:bg-green-700': job.completed == false}">
                                Completed Job
                            </button> -->

                            <a class="group inline-flex items-start text-sm space-x-2 text-gray-500 hover:text-gray-900">
                                <!-- Heroicon name: question-mark-circle -->
                                <svg class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                </svg>
                                <span>Job can be modified up to 24 hours before scheduled time.</span>
                            </a>

                            <a class="group inline-flex items-start text-sm space-x-2 text-gray-500 hover:text-gray-900">
                                <!-- Heroicon name: question-mark-circle -->
                                <svg class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                </svg>
                                <span>Any issue with a job can be reported after the job has been marked complete. If we missed something, please let us know ASAP.</span>
                            </a>
                        </div>
                    </div>



                </section>

            </div>
        </main>

    </div>    
</template>






<script>
import API from '@/assets/libraries/API_Requests'
import Cookies from '@/assets/libraries/basicFunctions'
import SpotifyAPI from '@/assets/libraries/SpotifyAPI'

import AlbumComponent from "@/components/AlbumComponent";
import PlaylistItem from "@/components/PlaylistItem";

import LoadingIndicator from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
// import func from '../../vue-temp/vue-editor-bridge';

export default {
    name: 'Stats',
    components: { LoadingIndicator, PlaylistItem, AlbumComponent },
    data() {
        return {
            stats_are_loading: false,
            parameters: [],

            playlists: [],
            selected_playlist: undefined,
            playlists_are_loading: false,

            songs_for_selected_playlist: [],
            songs_are_loading: false,

            loaded_user_profiles: {}

        }
    },
    computed: {
        users_added_songs: function() {
            let users_songs = {}

            for (let song of this.songs_for_selected_playlist) {
                if (users_songs[song.added_by.id] == undefined) {
                    users_songs[song.added_by.id] = 1
                }else {
                    users_songs[song.added_by.id] += 1;
                }
            }
            console.log('users_added_songs', users_songs);

            let users = Object.keys(users_songs).map((user) => { return { user:user, number_of_songs: users_songs[user] } });

            users.sort((a, b) => {
                return b.number_of_songs - a.number_of_songs
            })

            this.getUsersProfiles(Object.keys(users_songs))

            return users;
        }
    },
    methods: {
        getPlaylists: function() {
            return new Promise((resolve, reject) => {
                this.playlists_are_loading = true
                let user_id = this.$store.state.account_info.id

                SpotifyAPI.getAllUsersPlaylists(user_id).then((playlists) => {
                    this.playlists_are_loading = false
                    this.playlists = playlists

                    return resolve(playlists);
                }).catch((error) => {
                    this.playlists_are_loading = false
                    return reject(error);
                })  
            });
        },

        getSongsForPlaylist: function(playlist_id) {
            return new Promise((resolve, reject) => {
                this.songs_are_loading = true
                // this.songs_for_selected_playlist = undefined;

                SpotifyAPI.getAllSongsForPlaylist(playlist_id).then((songs) => {
                    this.songs_are_loading = false
                    this.songs_for_selected_playlist = songs

                    return resolve(songs);
                }).catch((error) => {
                    this.songs_are_loading = false
                    return reject(error);
                })  
            });
        },

        getUsersProfiles: function(user_ids) {
            
            return new Promise((resolve, reject) => {
                let users_promises = [];

                for (const user_id of user_ids) {
                    users_promises.push(new Promise((user_profile_resolve, user_profile_reject) => {
                            API.CallSpotify('GET', `/users/${user_id}`, null, function (response) {
                                if (response.error != undefined) {
                                    console.log('user profile error', response.error);
                                    return user_profile_reject();
                                }

                                return user_profile_resolve(response)
                            })
                    }))
                }

                Promise.all(users_promises).then((profiles) => {
                    for (const profile of profiles) {
                        // this.loaded_user_profiles[profile.id] = profile
                        this.$set(this.loaded_user_profiles, profile.id, profile) //Forced UI to update with change
                    }
                    return resolve(profiles);
                }).catch((error) => {
                    console.log(`Error in promises ${error}`)
                    return reject();
                })
            })

        },

        getJobData: function() {
            // console.log('getting job data', this.job_id)

            // return new Promise((resolve, reject) => {
            //     this.job_is_loading = true;

            //     API.Call('GET', `${API.BASE_API_URL}/jobs/${this.job_id}`, null, (response) => {
            //         if (response.error != undefined) {
            //             return reject();
            //         }
    
            //         console.log('Response', response)
            //         this.job = response
            //         // this.job.date = '2021-01-11T07:00:00.000Z'
            //         // this.job.original_job_id = undefined
            //         this.job_is_loading = false;
            //         resolve(response);
            //     })
            // });
        },

        selectPlaylist: function(playlist) {
            this.selected_playlist = playlist
            this.getSongsForPlaylist(playlist.id)
        }
    },
    async mounted() {
        console.log('Job detail view', this.$route)
        // this.job_id = this.$route.params.job_id;

        this.parameters = this.$route.query
        // console.log(this.parameters)

        // await this.getJobData();
        await this.spotify.setAccessToken(Cookies.readCookie('access_token'))
        await this.getPlaylists();
    }
}
</script>