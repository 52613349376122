<template>
    <div>
    <nav class="bg-gray-800">
        <div class="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
            <div class="flex items-center">
            <div class="flex-shrink-0">
                <img class="h-8 w-8" src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg" alt="Workflow">
            </div>
            <div class="hidden md:block">
                <div class="ml-10 flex items-baseline space-x-4">
                    <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
                    <router-link :to="link.route" v-for="link in links" :key="link.text"><a class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium" :class="{'bg-gray-900 text-white': $route.path == link.route}">{{link.text}}</a></router-link>
                </div>
            </div>
            </div>
            <div class="hidden md:block">
            <div class="ml-4 flex items-center md:ml-6">
                <button class="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <span class="sr-only">View notifications</span>
                <!-- Heroicon name: bell -->
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                </svg>
                </button>

                <!-- Profile dropdown -->
                <div class="ml-3 relative">
                <div v-if="$store.state.account_info.id != undefined">
                    <button class="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white" id="user-menu" aria-haspopup="true"
                        @click.stop="account_dropdown_is_open = !account_dropdown_is_open"
                        @keydown.esc="account_dropdown_is_open = false">
                        <span class="sr-only">Open user menu</span>
                        <img class="h-8 w-8 rounded-full" :src="$store.state.account_info.images[0].url" alt="">
                        <span class="text-gray-300 hover:text-white px-3 text-sm font-medium">{{$store.state.account_info.display_name}}</span>

                        <svg class="text-gray-300 h-5 w-5 hover:text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                    </button>
                </div>
                <div v-else>
                    <router-link to="/login"><a class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Login</a></router-link>
                </div>
                <!--
                    Profile dropdown panel, show/hide based on dropdown state.

                    Entering: "transition ease-out duration-100"
                    From: "transform opacity-0 scale-95"
                    To: "transform opacity-100 scale-100"
                    Leaving: "transition ease-in duration-75"
                    From: "transform opacity-100 scale-100"
                    To: "transform opacity-0 scale-95"
                -->
                <transition
                    enter-active-class="transition ease-out duration-100"
                    enter-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95"
                    >
                    <div class="origin-top-right absolute right-0 z-10 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                        v-show="account_dropdown_is_open"
                        style="display: none;" 
                        role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                        <a href="/account" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer" role="menuitem">Account / Settings</a>

                        <a @click.prevent.stop="logout()" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer" role="menuitem">Sign out</a>
                    </div>
                </transition>
                </div>
            </div>
            </div>
            <div class="-mr-2 flex md:hidden">
            <!-- Mobile menu button -->
            <button class="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                @click="mobile_menu_is_open = !mobile_menu_is_open">
                <span class="sr-only">Open main menu</span>
                <!--
                Heroicon name: menu

                Menu open: "hidden", Menu closed: "block"
                -->
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"
                    :class="{'hidden': mobile_menu_is_open, 'block': !mobile_menu_is_open }">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
                <!--
                Heroicon name: x

                Menu open: "block", Menu closed: "hidden"
                -->
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"
                    :class="{'block': mobile_menu_is_open, 'hidden': !mobile_menu_is_open }">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
            </div>
        </div>
        </div>

        <!--
        Mobile menu, toggle classes based on menu state.

        Open: "block", closed: "hidden"
        OG: class="hidden md:hidden"
        -->
        <div
            :class="{'block': mobile_menu_is_open, 'hidden': !mobile_menu_is_open }">
        <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
            <router-link :to="link.route" v-for="link in links" :key="link.text"><a class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium" :class="{'bg-gray-900 text-white': $route.path == link.route}">{{link.text}}</a></router-link>
        </div>
        <div class="pt-4 pb-3 border-t border-gray-700">
            <div class="flex items-center px-5">
            <div class="flex-shrink-0">
                <img class="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">
            </div>
            <div class="ml-3">
                <div class="text-base font-medium leading-none text-white">Tom Cook</div>
                <div class="text-sm font-medium leading-none text-gray-400">tom@example.com</div>
            </div>
            <button class="ml-auto bg-gray-800 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <span class="sr-only">View notifications</span>
                <!-- Heroicon name: bell -->
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                </svg>
            </button>
            </div>
            <div class="mt-3 px-2 space-y-1">
            <a href="/account" class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700">Account / Settings</a>

            <span @click.prevent="logout()" class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700">Sign out</span>
            </div>
        </div>
        </div>
    </nav>
    </div>

</template>

<script>
import API from '@/assets/libraries/API_Requests'
import Cookies from '@/assets/libraries/basicFunctions'

export default {
    name: "Header",
    data() {
        return {
            account_dropdown_is_open: false,
            mobile_menu_is_open: false,
            links: [
                { text: 'Home', route: '/'},
                { text: 'Stats', route: '/stats'},
                { text: 'Shuffle Play', route: '/shuffle'},
            ],
            hide_header_for_routes: ['/login']
        }
    },
    methods: {
        logout: function() {
            console.log('logged out...')
            Cookies.eraseCookie('access_token');
            Cookies.eraseCookie('should_be_admin');
            this.$store.commit('loggedOutUser')
            this.$router.push({ path: `/` })
            this.account_dropdown_is_open = false
        },
        checkIfLoggedIn: function() {
            return new Promise((resolve) => {
                if (Cookies.readCookie('access_token') != undefined || null) {
                    console.log('Logged in, getting me data...')

                    API.CallSpotify('GET', `/me`, null, (response) => {
                        if (response.error != undefined) {
                            this.logout()
                            return resolve();
                        }
        
                        console.log('Response', response)
                        this.$store.commit('loggedInUser', response)
                        console.log('Set spoitfy lib access token', Cookies.readCookie('access_token'))
                        this.spotify.setAccessToken(Cookies.readCookie('access_token'))
                        return resolve(response);
                    })
                }else {
                    this.logout();
                    return resolve();
                }
            });
        }
    },
    async mounted() {
        await this.checkIfLoggedIn()
        // if (this.$store.state.account_info.is_admin == true) {
        //     this.links = [
        //         { text: 'Home', route: '/'},
        //         { text: 'Agenda', route: '/agenda'},
        //         { text: 'Schedule Job', route: '/schedule'},
        //         { text: 'Jobs', route: '/jobs'},
        //         { text: 'Service Locations', route: '/locations'},
        //         { text: 'Customers', route: '/customers'},
        //         { text: 'Invoices', route: '/invoices'},
        //         { text: 'Settings', route: '/settings'},
        //     ]
        // }
        
    }
}
</script>

<style lang="stylus" scoped>

</style>

