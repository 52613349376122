// import $ from 'jquery'
import moment from 'moment'
// var moment = require('./moment.js')

/* eslint-disable no-unused-vars */
const Cookies = {

     createCookie: function(name, value, days) {
          var expires = "";
          if (days) {
              var date = new Date();
              date.setTime(date.getTime() + (days*24*60*60*1000));
              expires = "; expires=" + date.toUTCString();
          }
          document.cookie = name + "=" + value + expires + "; path=/";
     },
     readCookie: function(name) {
          var nameEQ = name + "=";
          var ca = document.cookie.split(';');
          for(var i=0;i < ca.length;i++) {
              var c = ca[i];
              while (c.charAt(0)==' ') c = c.substring(1,c.length);
              if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
          }
          return null;
     },
     eraseCookie: function(name) {
          this.createCookie(name, "", -1);
     }
}

export default Cookies

function chr4(){
     return Math.random().toString(16).slice(-4);
}

function isInt(n){
    return Number(n) === n && n % 1 === 0;
}

function isFloat(n){
    return Number(n) === n && n % 1 !== 0;
}

function stringToBool(boolString) {
     if (boolString.toLowerCase().indexOf("true") != -1) {
          return true;
     }else {
          return false;
     }
}

function convertStringToBool(bool_string) {
     if (bool_string == null) {return false;}
     if (typeof(bool_string) == 'boolean') { return bool_string; }

     bool_string = bool_string.toLowerCase();
     if (bool_string.indexOf('true') != -1 || bool_string.indexOf('yes') != -1) { return true; }
     if (bool_string.indexOf('false') != -1 || bool_string.indexOf('no') != -1) { return false; }
     return undefined;
}

String.prototype.toBool = function() {
     return convertStringToBool(this);
}

String.prototype.obfuscateEnd = function(number_of_characters_to_change = 3, char_to_replace_with = "*") {
     return this.substring(0, this.length - number_of_characters_to_change) + this.substring(this.length - number_of_characters_to_change).split('').map(_=>{return char_to_replace_with;}).join('');
}

String.prototype.obfuscateBeginning = function(number_of_characters_to_change = 3, char_to_replace_with = "*") {
     return this.substring(this.length - number_of_characters_to_change).split('').map(_=>{return char_to_replace_with;}).join('') + this.substring(0, this.length - number_of_characters_to_change);
}

String.prototype.objuscateTillEnd = function(number_of_characters_to_leave_at_end, do_not_change_char = undefined, char_to_replace_with = '*') {
     if (number_of_characters_to_leave_at_end == undefined || null) { number_of_characters_to_leave_at_end = this.length; }
     return this.substring(0, this.length - number_of_characters_to_leave_at_end).split('').map((char)=>{return char == do_not_change_char ? char : char_to_replace_with;}).join('') + this.substring(this.length - number_of_characters_to_leave_at_end);
}

String.prototype.prettyDate = function() {
     // return moment(this).utc().format('MMMM DD, YYYY');
     let date = moment(this).utc();
     return date.calendar(null, {
          sameDay: '[Today At] h:mm',
          lastDay: '[Yesterday At] h:mm',
          lastWeek: '[Last] dddd',
          sameElse: 'MMMM DD, YYYY'
     });
}
String.prototype.getFormattedDate = function() {
     let date = moment(this + '+00:00');
     let now = moment();

     var duration = moment.duration(now.diff(date));
     var hours = duration.asHours();

     if (hours < 24) {
          return date.fromNow();
     }
     return date.format('l');
}
String.prototype.getLocalizedShortDate = function() {
     let date = moment(this + '+00:00');
     return date.format('l');
}

Boolean.prototype.toggle = function() {
     return this == true ? false : true;
}

String.prototype.toBinary = function() {
     let string = this;
     let binary = '';
     for (var i = 0; i < string.length; i++) {
          binary += string[i].charCodeAt(0).toString(2);
     }
     return binary;
}


function splitFormatedStringIntoTimeElements(formatedString) {
     if (formatedString == undefined || null) { return {"hour":"","min":"", "sec":"", "milSec":""}; }
     formatedString = formatedString.replace(/s/g, '');
     var splitTime = formatedString.split(":");
     var hourString = "0";
     var minString = "0";
     var secondString = "0";
     var miliSecondString = "0";
     if (splitTime.length == 3) { hourString = splitTime[0]; minString = splitTime[1]}
     else if (splitTime.length == 2) {minString = splitTime[0]}

     if (splitTime[splitTime.length - 1].indexOf(".") != -1) {
          var secondsAndMillSec = splitTime[splitTime.length - 1].split(".");
          splitTime[splitTime.length - 1] = secondsAndMillSec[0];
          splitTime.push(secondsAndMillSec[1]);
          secondString = secondsAndMillSec[0] == "" ? "0" : secondsAndMillSec[0];
          miliSecondString = secondsAndMillSec[1];
     }else {
          secondString = splitTime[splitTime.length - 1];
     }
     return {"hour":hourString,"min":minString, "sec":secondString, "milSec" : miliSecondString};
}

String.prototype.isAlphaNumeric = function() {
  var regExp = /^[A-Za-z0-9]+$/;
  return (this.match(regExp));
};

function convertFormatedStringIntoSeconds(formatedString) {
     if (formatedString.replace(/\s/g, '') == '') { return 0; }
     if (formatedString == undefined || null) { return 0; }
     var timeElements = splitFormatedStringIntoTimeElements(formatedString);
     //console.log(timeElements);
     var miliSecFormated = 0.0;
     if (timeElements.milSec.length < 2) {miliSecFormated = parseInt(timeElements.milSec) * 0.1;}else {miliSecFormated = parseInt(timeElements.milSec) * 0.01}
     var seconds = ((parseInt(timeElements.hour) * 60) * 60) + (parseInt(timeElements.min) * 60) + parseInt(timeElements.sec) + miliSecFormated;
     return seconds;
}

String.prototype.toSeconds = function () {
     return convertFormatedStringIntoSeconds(this);
}

String.prototype.reverse = function() {
     return this.split("").reverse().join("");
}

String.prototype.frequencyOfChar = function(char) {
     let frequency = 0;
     for (const letter of this) {
          if (letter == char) { frequency++; }
     }
     return frequency;
}

function getDecimalPlaces(number, decimalPlaces) {
     var toReturn = "";
     if ((number + "").indexOf(".") == -1) {return number;}
     let splitNumber = (number + "").split(".");
     toReturn = splitNumber[0] + ".";
     let decimals = splitNumber[1]
     for (var i = 0, len = decimals.length; i < len && i < decimalPlaces; i++) {
          toReturn += decimals.charAt(i);
     }
     return parseFloat(toReturn);
}

Number.prototype.decimals = function(decimalPlaces) {
     return getDecimalPlaces(this, decimalPlaces);
}

function getGreatestValue(array) {
     let greatest_value = Number.MIN_SAFE_INTEGER;
     for (const value of array) {
          if (value > greatest_value) { greatest_value = value; }
     }
     return greatest_value;
}

function getLowestValue(array) {
     let lowest_value = Number.MAX_SAFE_INTEGER;
     for (const value of array) {
          if (value < lowest_value) { lowest_value = value; }
     }
     return lowest_value;
}

function formatSecondsIntoString(string) {
	return (""+string).toDisplayableTime();
}

function formatDisplayableTime(secondsToFormat, decimal_places = 2) {
     // console.log("typeof", typeof(secondsToFormat));
     if (secondsToFormat < 0) { return "NEGATIVE"; }

     var incomingValue = parseFloat(parseFloat(secondsToFormat).toFixed(2));//this;
     var sec_num = parseInt(secondsToFormat, 10);//parseInt(this, 10); // don't forget the second param
     var hours   = Math.floor(sec_num / 3600);
     var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
     var seconds = sec_num - (hours * 3600) - (minutes * 60);
     var milliseconds = secondsToFormat.toString();//(this).toString()
     var digitsBeforeDecimal = parseInt(secondsToFormat).toString().length;//parseInt(this).toString().length

     //Add the ability to round milliseconds
     // console.log('milliseconds', milliseconds);
     // if (decimal_places <= 1) {
     //      milliseconds.replaceCharAt(digitsBeforeDecimal + 1, (parseInt('0.' + milliseconds.substring(digitsBeforeDecimal + 2))).toFixed(2))
     // }else { //Max 100th second
     //
     // }

     // var milSecs = ""
     // if (milliseconds[digitsBeforeDecimal + 1] !== undefined) {
     //      milSecs += milliseconds[digitsBeforeDecimal + 1];
     // }else {milSecs += "00"}
     // if (milliseconds[digitsBeforeDecimal + 2] !== undefined){
     //      milSecs += milliseconds[digitsBeforeDecimal + 2];
     // }else {
     //      // console.log("Here");
     // }

     var mil_secs = '';
     if (milliseconds[digitsBeforeDecimal + 1] !== undefined && decimal_places >= 1) { mil_secs += milliseconds[digitsBeforeDecimal + 1]; }//else { mil_secs += "00"; }
     if (milliseconds[digitsBeforeDecimal + 2] !== undefined && decimal_places >= 2) { mil_secs += milliseconds[digitsBeforeDecimal + 2]; }//else { }

     if (hours   < 10) { hours   = "0" + hours; }
     if (minutes < 10) { minutes = "0" + minutes; }
     if (seconds < 10) { seconds = "0" + seconds; }

     // /if (milSecs.includes('undefined')) { console.log("undifind, " + incomingValue,hours,minutes,seconds,milSecs); }
     // if (milSecs.length == 1) {milSecs = milSecs + "0"}else if (milSecs.length == 0) {milSecs = "00";}
     // if (mil_secs.length == 1) { mil_secs = mil_secs + "0"; }else if (mil_secs.length == 0) { mil_secs = "00"; }
     let number_of_trailing_zeros = 2 - mil_secs.length;
     if (number_of_trailing_zeros + mil_secs.length > decimal_places) { number_of_trailing_zeros = decimal_places - mil_secs.length; }
     for (let i = 0; i < number_of_trailing_zeros; i++) { mil_secs = mil_secs + "0"; }

     if (hours > 0) {
         return hours+':'+minutes+':'+seconds + (decimal_places >= 1 ? ("." + mil_secs) : '');
    }else {
         return minutes+':' + seconds + (decimal_places >= 1 ? ("." + mil_secs) : '');
    }
}

String.prototype.toDisplayableTime = function (decimal_places) {
     return formatDisplayableTime(this, decimal_places);
}

String.prototype.shortenTime = function () {
     return shortenDisplayTime(this);
}

function shortenDisplayTime(displayTimeToShorten) {
     // console.log("Time:", displayTimeToShorten);
     if (displayTimeToShorten == undefined || null) {return '';}
     if (displayTimeToShorten == "" || displayTimeToShorten == " ") {return '';}
     var splitTime = splitFormatedStringIntoTimeElements(displayTimeToShorten);
     // console.log("SPlit:", splitTime);
     var newDisplayTime = "";
     var hasMilSec = false, hasSec = false, hasMin = false, hasHour = false;
     if (splitTime.min != "0" && splitTime.min != "00") {
          // console.log("Test: ", splitTime.min[0]);
          if (splitTime.min[0] == "0") {newDisplayTime += splitTime.min[1];}else {newDisplayTime += splitTime.min;}
          hasMin = true;
     }
     if (splitTime.sec != "0" && splitTime.sec != "00") {
          if (hasMin) {newDisplayTime += ":"}
          if (splitTime.sec[0] == "0" && !hasMin) {newDisplayTime += splitTime.sec[1];}else {newDisplayTime += splitTime.sec;}
          hasSec = true;
     }else {
          //Empty
     }
     if (splitTime.milSec != "0" && splitTime.milSec != "00") {
          if (hasMin && hasSec == false) {newDisplayTime += ":00"}else if (hasSec == false) {newDisplayTime += "0"}
          if (splitTime.milSec[1] == "0") {
               newDisplayTime += "." + splitTime.milSec[0];
          }else {
               newDisplayTime += "." + splitTime.milSec;
          }
          hasMilSec = true;
     }else {
          if ((hasSec && hasMin == false) && newDisplayTime.indexOf("s") == -1) {newDisplayTime += "s"}
     }
     if (!hasMilSec && !hasSec && hasMin) {
          newDisplayTime += ":00";
     }
     if (newDisplayTime == "") {
          newDisplayTime = "0";
     }
     return newDisplayTime;
}

String.prototype.replaceCharAt = function(index, replacement) {
	if (index >= this.length) { return this.valueOf(); }
	return this.substring(0, index) + replacement + this.substring(index + 1);
}

/*
	Output: Hello_World
*/

// Array.prototype.test = function() {
//      return this[this.length - 1];
// }
Object.defineProperty(Array.prototype, 'first', {
    value: function() { return this[0]; }
});
Object.defineProperty(Array.prototype, 'last', {
    value: function() { return this[this.length - 1]; }
});

//
// Array.prototype.first = function() {
//      return this[0] ? this[0] : undefined;
// }

String.prototype.contains = function(contains_string) {
     return this.includes(contains_string);
}

function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16)
    } : null;
}

String.prototype.toRGBA = function(alpha = 1.0) {
     var rgb = hexToRgb(this);
     return "rgba("+rgb.red+", "+rgb.green+", "+rgb.blue+", "+alpha+")";
}

function stringBetweenTwoKeywords(string, first_keyword, second_keyword) {
     if (string == undefined || string == " ") { return ""; }
     var string_after_first_keyword = string;
     if (first_keyword != "") {
          let first_split = string.split(first_keyword);
          if (first_split.length == 1 ) { console.error("The first_keyword was not found in the string;", first_keyword, string); }
     }

     if (second_keyword != undefined) {
          return string_after_first_keyword.split(second_keyword)[0];
     }
     return string_after_first_keyword;
}

String.prototype.stringBetween = function(first_keyword, second_keyword) {
     return stringBetweenTwoKeywords(this, first_keyword, second_keyword);
}

function updateQueryStringParameter(uri, key, value) {
  var re = new RegExp("([?&])" + key + "=.*?(&|#|$)", "i");
  if( value === undefined ) {
    if (uri.match(re)) {
        return uri.replace(re, '$1$2');
    } else {
        return uri;
    }
  } else {
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
    } else {
    var hash =  '';
    if( uri.indexOf('#') !== -1 ){
        hash = uri.replace(/.*#/, '#');
        uri = uri.replace(/#.*/, '');
    }
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    return uri + separator + key + "=" + value + hash;
  }
  }
}

String.prototype.toTitleCase = function() {
     // var string = this;
     if (this == undefined || null) { return this; }
     if (this.replace(/\s/g, '') == "") { return ""; }
     // string = string.replace(/_/g, ' ');
     return this.replace(/\w\S*/g, function(txt){ return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
     // return string.replace(/\w\S*/g, function(txt){ return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
}

Math.randomIntBetween = function(min, max) {
//     return random = Math.floor(Math.random() * (max - min)) + min;
     return Math.floor(Math.random() * (max - min)) + min;
}

Math.randomFloatBetween = function(min, max, decimal_places = 2) {
     if (decimal_places == Infinity) { return Math.random() * (max - min) + min; }
     return parseFloat((Math.random() * (max - min) + min).toFixed(decimal_places));
}

Math.randomBoolean = function() {
     return Math.randomIntBetween(0,1) == 0 ? false : true;
}

Object.defineProperty(Number.prototype, "decimal", {
     get: function decimal() {
          Number.precision = "precision" in Number ? Number.precision : 3;
          var f = Math.pow(10, Number.precision);
          return Math.round( this * f ) / f;
     }
});
