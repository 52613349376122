const ShuffleHandler = {}

ShuffleHandler.options = {
    number_of_songs_to_shuffle: 50,
    only_include_songs_from_users: [],//['dawson_seibold', 'drunkensalor23'],//[],
    exclude_songs_from_users: [], //['dawson_seibold'],
    evenly_distribute_songs_between_users: true,
    only_include_artist_once_per_shuffle: true,
} 

/**
 * Shuffles an array using a shuffle algorithm
 * @param {array} array_to_shuffle 
 * @returns {array} The shuffled array
 */
ShuffleHandler.shuffleArray = (array_to_shuffle) => {
    if (typeof(array_to_shuffle) != 'object') { return array_to_shuffle; }
    return array_to_shuffle.shuffle()
}

/**
 * Shuffle the given array using the default or given options
 * @param {array} array_to_shuffle 
 * @param {object} options 
 * @returns {array} The shuffled array
 */
ShuffleHandler.shuffleUsingOptions = (array_to_shuffle, options = ShuffleHandler.options) => {
    let shuffled = array_to_shuffle.shuffle().shuffle().shuffle();
    // console.log('shuffled', shuffled.map((e) => e.track.name))
    
    let number_of_users_in_playlist = 1;
    let users_in_playlist = [...new Set(shuffled.map((song) => { return song.added_by.id; }))];
    number_of_users_in_playlist = users_in_playlist.filter((user_id) => {
        if (options.exclude_songs_from_users.indexOf(user_id) != -1) { return false; } //Exclude user
        if (options.only_include_songs_from_users.length != 0 && options.only_include_songs_from_users.indexOf(user_id) == -1) { return false; } //Exclude user
        return true;
    }).length;

    let max_number_of_songs_to_select = options.number_of_songs_to_shuffle;
    let number_of_songs_inserted_by_user = {};
    let max_number_of_songs_per_person = -1;

    if (options.evenly_distribute_songs_between_users == true) {
        max_number_of_songs_per_person = Math.floor(max_number_of_songs_to_select / number_of_users_in_playlist);
        max_number_of_songs_to_select = max_number_of_songs_per_person * number_of_users_in_playlist;
    }

    let artist_used_per_user = {}; // Example: { drunkensalor23 : {} }
    let artist_used = {};

    let selected_songs = [];
    for (const song of shuffled) {
        if (options.exclude_songs_from_users.indexOf(song.added_by.id) != -1) { continue; } //Exclude user
        if (options.only_include_songs_from_users.length != 0 && options.only_include_songs_from_users.indexOf(song.added_by.id) == -1) { continue; } //Exclude user
        
        if (max_number_of_songs_per_person != -1) {
            if (number_of_songs_inserted_by_user[song.added_by.id] != undefined && number_of_songs_inserted_by_user[song.added_by.id] >= max_number_of_songs_per_person) {  console.log('continue', number_of_songs_inserted_by_user[song.added_by.id]); continue; }
        }

        if (artist_used_per_user[song.added_by.id] != undefined && artist_used_per_user[song.added_by.id][song.track.artists[0].id] != undefined) { continue; } // Skips if duplicate artist

        selected_songs.push(song);
        if (number_of_songs_inserted_by_user[song.added_by.id] == undefined) { number_of_songs_inserted_by_user[song.added_by.id] = 1; }else { number_of_songs_inserted_by_user[song.added_by.id] += 1; }

        if (artist_used[song.track.artists[0].id] == undefined) { artist_used[song.track.artists[0].id] = 1; } else { artist_used[song.track.artists[0].id] += 1; } // skips duplicate songs

        if (artist_used_per_user[song.added_by.id] == undefined) { artist_used_per_user[song.added_by.id] = {}; } // Check if user as added a song yet and creates object if so
        if (artist_used_per_user[song.added_by.id][song.track.artists[0].id] == undefined) { artist_used_per_user[song.added_by.id][song.track.artists[0].id] = 1; } else { artist_used_per_user[song.added_by.id][song.track.artists[0].id] += 1; } //skips duplicate songs per user


        if (selected_songs.length >= max_number_of_songs_to_select) { break; }
    }
    //shuffled.splice(options.number_of_songs_to_shuffle || 50, shuffled.length - options.number_of_songs_to_shuffle || 50) //Remove all but the first X songs

    selected_songs = selected_songs.shuffle(); //Shuffle selected songs as the user with more songs in the playlist will generally have most of their songs appear at the beginning of the shuffle and the users with least will just fill in at the end.

    return selected_songs;
}

export default ShuffleHandler

Array.prototype.shuffle = function() {
    return shuffle(this);
}

function shuffle(array) {
    var m = array.length, t, i;

    // While there remain elements to shuffle…
    while (m) {

        // Pick a remaining element…
        i = Math.floor(Math.random() * m--);

        // And swap it with the current element.
        t = array[m];
        array[m] = array[i];
        array[i] = t;
    }

    return array;
}