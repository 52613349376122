<template>

    <section aria-labelledby="payment_details_heading" class="relative">
        <LoadingIndicator :active="player_is_loading"  
            :is-full-page="false"></LoadingIndicator>

            <div class="shadow sm:rounded-md sm:overflow-hidden">
                <div class="bg-white py-6 px-4 sm:p-6">
                    <div class="w-full flex justify-between items-center h-10 mb-5">
                        <h2 class="text-lg font-medium text-gray-900">Now Playing</h2>
                        <div class="flex space-x-1">
                            <button class="flex justify-center items-center p-2 text-gray-800 rounded-full hover:bg-gray-200" @click.stop.prevent="getPlayerInfo()">
                                <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                </svg>
                            </button>
                            <button class="flex justify-center items-center p-2 text-gray-800 rounded-full hover:bg-gray-200" @click.stop.prevent="toggleFullScreen()">
                                <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div class="w-full flex items-center justify-center mb-5" v-if="player_info == undefined || player_info.item == undefined">
                        <span class="text-gray-700">No song is playing</span>
                    </div>

                    <div class="w-full flex justify-center" v-if="player_info != undefined && player_info.item != undefined">
                        <div class="bg-gray-50 shadow-2xl w-44 h-44 flex justify-center items-center relative">
                            <img :src="player_info.item.album.images[0].url" class="rounded-lg w-44 h-44 overflow-hidden">
                            <span v-if="player_info.item.album.images[0].url == undefined">Error Loading Artwork</span>

                            <div class="flex justify-center items-center absolute bottom-0 right-0" 
                                style="bottom: -0.75rem; right: -1rem;"
                                v-if="isCurrentSongInLitPlaylist.in_playlist == true && isCurrentSongInLitPlaylist.added_by != undefined && isCurrentSongInLitPlaylist.added_by.id != undefined"
                                :content="`Added by ${isCurrentSongInLitPlaylist.profile == undefined ? isCurrentSongInLitPlaylist.added_by.id : isCurrentSongInLitPlaylist.profile.display_name}`" v-tippy>
                                <img v-if="isCurrentSongInLitPlaylist.profile != undefined && isCurrentSongInLitPlaylist.profile.images[0].url != undefined" :src="isCurrentSongInLitPlaylist.profile.images[0].url" class="rounded-full shadow-2xl w-10 h-10 overflow-hidden" style="box-shadow: 0px 0px 17px 3px rgb(0 0 0 / 45%);">
                                <span v-else class="rounded-full shadow-2xl w-10 h-10 overflow-hidden bg-pink-600 text-white flex justify-center items-center" style="box-shadow: 0px 0px 17px 3px rgb(0 0 0 / 45%);">{{isCurrentSongInLitPlaylist.added_by.id.substring(0,2).toUpperCase()}}</span>
                            </div>
                        </div>

                        
                    </div>

                    <!-- flex -->
                    <div class="w-full flex flex-col mt-5 mb-5 items-center relative" v-if="player_info != undefined && player_info.item != undefined">
                        <!-- <MarqueeText :duration="20"><span class="text-center text-gray-800 font-medium">{{player_info.item.name}}</span></MarqueeText> -->
                        <span class="text-center text-gray-800 font-medium truncate whitespace-no-wrap" :content="player_info.item.name" v-tippy>{{player_info.item.name}}</span>

                        <!-- <MarqueeText :duration="30">
                            <span class="text-gray-500 font-small">
                                <span v-for="(artist, index) of player_info.item.artists" :key="artist.id">{{artist.name}}{{index != player_info.item.artists.length - 1 ? ', ' : ''}}</span>
                            </span>
                        </MarqueeText> -->

                        <span class="text-gray-500 font-small truncate whitespace-no-wrap max-w-full" :content="player_info.item.artists.map(a => a.name).join(', ')" v-tippy>
                            <span v-for="(artist, index) of player_info.item.artists" :key="artist.id">{{artist.name}}{{index != player_info.item.artists.length - 1 ? ', ' : ''}}</span>
                        </span>
                    </div>


                    <div class="flex justify-center items-center">
                        <div class="rounded-full text-gray-600 hover:text-gray-900 hover:bg-gray-300">
                            <button class="flex justify-center items-center p-2 rounded-full" @click.stop.prevent="previousSong()">
                                <svg class="w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0019 16V8a1 1 0 00-1.6-.8l-5.333 4zM4.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0011 16V8a1 1 0 00-1.6-.8l-5.334 4z" />
                                </svg>
                            </button>
                        </div>
                        <div class="rounded-full text-gray-600 hover:text-gray-900 hover:bg-gray-300">
                            <button class="flex justify-center items-center p-2 rounded-full" @click.stop.prevent="playPause()">
                                <svg class="w-10 h-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path v-if="player_info == undefined || player_info.is_playing == false" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                                    <path v-if="player_info == undefined || player_info.is_playing == false" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />

                                    <path v-if="player_info != undefined && player_info.is_playing == true" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </button>
                        </div>
                        <div class="rounded-full text-gray-600 hover:text-gray-900 hover:bg-gray-300">
                            <button class="flex justify-center items-center p-2 rounded-full" @click.stop.prevent="nextSong()">
                                <svg class="w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11.933 12.8a1 1 0 000-1.6L6.6 7.2A1 1 0 005 8v8a1 1 0 001.6.8l5.333-4zM19.933 12.8a1 1 0 000-1.6l-5.333-4A1 1 0 0013 8v8a1 1 0 001.6.8l5.333-4z" />
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div class="flex justify-end items-center text-gray-500 text-sm space-x-1 mt-2" v-if="player_info != undefined && player_info.device != undefined && player_info.device.name != undefined">
                        <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
                        </svg>
                        <span>{{player_info.device.name}}</span>
                    </div>

                </div>
            </div>
        </section>

</template>

<script>

import API from '@/assets/libraries/API_Requests'

import LoadingIndicator from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';

import DatabaseHelper from '@/assets/libraries/Database'
// import MarqueeText from 'vue-marquee-text-component'

export default {
    name: "now-playing",
    components: { LoadingIndicator },
    props: ["full-screen", "user_profiles"],
    data() {
        return {
            player_is_loading: false,
            player_info: undefined,

            lit_playlist_songs: [],
            track_id_for_lit_playlist_song_index: {},

            refresh_player_timeout: undefined
        }
    },
    computed: {
        isCurrentSongInLitPlaylist: function() {
            if (this.player_info.item == undefined) { return {in_playlist: false, added_by: undefined }; }
            let index = this.track_id_for_lit_playlist_song_index[this.player_info.item.id]
            if (index == undefined) { return {in_playlist: false, added_by: undefined }; }

            let info = {in_playlist: true, added_by: this.lit_playlist_songs[index].added_by }

            if (this.user_profiles != undefined && this.user_profiles[info.added_by.id] != undefined) {
                info.profile = this.user_profiles[info.added_by.id];
                // info.profile.image_url = info.profile.images[0].url
            }

            return info;
        }
    },
    methods: {
        getPlayerInfo: function() {
            return new Promise((resolve, reject) => {
                new Promise((api_resolve) => {
                    API.CallSpotify('GET', `/me/player`, null, function (response) {
                        if (response == undefined || response.error != undefined) {
                            console.log('player error', response?.error);
                            return reject(response?.error);
                        }

                        return api_resolve(response)
                    })
                }).then((info) => {
                    this.player_info = info;

                    let time_to_refresh = 1000 * 60 * 3; //3 minutes
                    if (info.is_playing == true) { time_to_refresh = info.item.duration_ms - info.progress_ms + 500 } 
                    console.log('ms till refresh', time_to_refresh)

                    window.clearTimeout(this.refresh_player_timeout)
                    this.refresh_player_timeout = setTimeout(this.getPlayerInfo, time_to_refresh)
                    return resolve();
                }).catch((error) => {
                    return reject(error);
                });
            });
        },

        getLitPlaylistSongs: function() {
            console.log('here')
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve, reject) => {
                let stored_lit_playlist_songs = await DatabaseHelper.PlaylistManager.getPlaylist('1iZwQHiG2a4jQFKiR1MSay');
                console.log('stored_lit_playlist_songs', stored_lit_playlist_songs)
                if (stored_lit_playlist_songs == undefined) { return reject(); }

                this.lit_playlist_songs = stored_lit_playlist_songs;

                this.track_id_for_lit_playlist_song_index = {};
                for (const [index, song] of this.lit_playlist_songs.entries()) {
                    this.track_id_for_lit_playlist_song_index[song.track.id] = index;
                }

                return resolve(stored_lit_playlist_songs);
            })
        },

        previousSong: function() {
            return this.executePlayerCommand('POST', 'previous');
        },
        nextSong: function() {
            return this.executePlayerCommand('POST', 'next');
        },
        playPause: function() {
            return this.executePlayerCommand('PUT', this.player_info.is_playing ? 'pause' : 'play');
        },
        executePlayerCommand: function(http_method = "PUT", action = "play") {
            return new Promise((resolve, reject) => {
                new Promise((api_resolve) => {
                    API.CallSpotify(http_method, `/me/player/${action}`, null, function (response) {
                        if (response != undefined && response.error != undefined) { return reject(response.error); }
                        return api_resolve(response)
                    })
                }).then(() => {
                    this.getPlayerInfo();
                    return resolve()
                }).catch((error) => {
                    this.getPlayerInfo();
                    return reject(error);
                });
            });
        }
    },
    async mounted() {
        // this.updateRequiredFields()
        // console.log('location: ', this.location, this.service_location_is_loading)

        await this.getPlayerInfo();
        await this.getLitPlaylistSongs();

        DatabaseHelper.ProfileManager.getAllProfiles().then((profiles) => {
            console.log('Grabbed cahced profiles', profiles)
            for (const profile of profiles) {
                // this.user_profiles[profile.id] = profile
                this.$set(this.user_profiles, profile.id, profile) //Forced UI to update with change
            }
        })
    },
    watch: {
        // location: function(new_value) {
        //     this.form_data = {...this.form_data, ...cloneDeep(new_value)}

            // this.location_data = {...this.location_data, ...new_value}
            // this.location_data = new_value

            // this.original_values = cloneDeep(new_value);
            // this.original_location_data = JSON.parse(JSON.stringify(new_value))
        //     this.has_been_edited = false
        //     this.updateRequiredFields();
        // }
    }
};

</script>
