
<template>

    <section class="relative h-full bg-white px-4 py-5 sm:px-6 sm:rounded-lg shadow">
        <h2 id="timeline-title" class="text-lg font-medium text-gray-900">Selected Playlist</h2>

        <div class="flex justify-center mt-5" v-if="info == undefined">
            <span class="text-gray-500">No playlist selected</span>
        </div>

        <!-- <div class="mt-3 bg-gray-200 shadow rounded-lg flex p-4" -->
        <div class="mt-3"
            v-if="info != undefined">
            
            <dl class="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">

                <div class="col-span-1 sm:col-span-2 flex items-center justify-center mb-4 mt-4">
                    <div class="bg-gray-50 shadow-2xl w-44 h-44 flex justify-center items-center relative">
                        <img :src="info.images[0].url" class="rounded-lg w-44 h-44 overflow-hidden">
                        <span v-if="info.images[0].url == undefined">Error Loading Artwork</span>
                    </div>
                </div>

                <div class="col-span-1 sm:col-span-2">
                    <dt class="text-lg font-medium text-gray-700">
                        {{info.name}}
                    </dt>
                    <dt class="mt-1 text-sm font-medium text-gray-500">
                        {{info.description}}
                    </dt>
                </div>

                <div class="sm:col-span-1">
                    <dt class="text-sm font-medium text-gray-500">
                        Created by
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                        <a :href="info.owner.external_urls.spotify">{{info.owner.display_name}}</a>
                    </dd>
                </div>
                <div class="sm:col-span-1">
                    <dt class="text-sm font-medium text-gray-500">
                        Songs
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                        {{info.tracks.total}}
                    </dd>
                </div>
                <!-- 
                <div class="sm:col-span-1">
                    <dt class="text-sm font-medium text-gray-500">
                        Service Location
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 flex flex-col">
                        <a :href="`/service-locations/${job.service_location.id}`" target="_blank" content="Service Location Address - Click to view more" v-tippy><span>{{job.service_location.address_line_1}}</span></a>
                        <span>{{job.service_location.address_line_2}}</span>
                        <span>{{job.service_location.city}}, {{job.service_location.state}} {{job.service_location.postal_code}}</span>

                        <div v-if="is_admin == true"><span class="inline-flex items-center px-2 py-0.5 mt-0 rounded text-sm font-medium" :class="`bg-${job.service_location.district.hex_color}-200 text-${job.service_location.district.hex_color}-800`" content="Service District" v-tippy>{{job.service_location.district.display_name}}</span></div>
                    </dd>
                </div>

                <div class="sm:col-span-1" v-if="is_admin == true">
                    <dt class="text-sm font-medium text-gray-500">
                        Customer
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 flex flex-col">
                        <a :href="`/customers/${job.customer.UUID}`" target="_blank" content="Customer Name - Click to view more" v-tippy><span>{{job.customer.first_name}} {{job.customer.last_name}}</span></a>
                        <a :href="`tel:${job.customer.primary_phone_number}`" content="Primary # - Click to call" v-tippy><span>P: {{job.customer.primary_phone_number | phone_number_formatter}}</span></a>
                        <a :href="`tel:${job.customer.secondary_phone_number}`" v-if="job.customer.secondary_phone_number != undefined" content="Secondary # - Click to call" v-tippy><span>S: {{job.customer.secondary_phone_number | phone_number_formatter}}</span></a>
                        <div v-if="is_admin == true"><span class="inline-flex items-center px-2 py-0.5 mt-0 rounded text-sm font-medium" :class="`bg-purple-200 text-purple-800`" content="Customer Type / Plan" v-tippy>{{job.customer.customer_type.display_name}}</span></div>
                    </dd>
                </div> -->

            </dl>
        </div>
    </section>

</template>

<script>

// import API from '@/assets/libraries/API_Requests'

// import LoadingIndicator from "vue-loading-overlay";
// import 'vue-loading-overlay/dist/vue-loading.css';

// import MarqueeText from 'vue-marquee-text-component'

export default {
    name: "album_item",
    components: { },
    props: ["info"],
    data() {
        return {

        }
    },
    methods: {
    },
    mounted() {
        // this.updateRequiredFields()
        // console.log('location: ', this.location, this.service_location_is_loading)
    },
    watch: {
        // location: function(new_value) {
        //     this.form_data = {...this.form_data, ...cloneDeep(new_value)}

            // this.location_data = {...this.location_data, ...new_value}
            // this.location_data = new_value

            // this.original_values = cloneDeep(new_value);
            // this.original_location_data = JSON.parse(JSON.stringify(new_value))
        //     this.has_been_edited = false
        //     this.updateRequiredFields();
        // }
    }
};

</script>
