import Vue from 'vue'
import Router from 'vue-router'

import Cookies from '@/assets/libraries/basicFunctions'

import Home from './views/Home.vue'

import Login from './views/Login.vue'

import Stats from './views/Stats.vue'
import Shuffle from './views/Shuffle.vue'
// import Account from './views/Account.vue'
// import Jobs from './views/Jobs.vue'
// import Job from './views/Job.vue'
// import Schedule from './views/Schedule.vue'
// import Agenda from './views/Agenda.vue'
// import RenewPlan from './views/RenewPlan.vue'

// import Location from './views/Location.vue'

// import PaymentSuccess from './views/payment/SuccessTransaction.vue'
// import PaymentFail from './views/payment/FailedTransaction.vue'


Vue.use(Router)

let routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    
    {
        path: '/login',
        name: 'login',
        component: Login
    },

   {
        path: '/stats',
        name: 'stats',
        component: Stats,
        protected: true
    },
    {
        path: '/shuffle',
        name: 'shuffle',
        component: Shuffle,
        protected: true
    },

    
    // {
    //     path: '/jobs',
    //     name: 'jobs',
    //     component: Jobs,
    //     protected: true
    // },
    // {
    //     path: '/jobs/:job_id',
    //     name: 'job',
    //     component: Job,
    //     protected: true
    // },
    // {
    //     path: '/schedule',
    //     name: 'schedule',
    //     component: Schedule,
    //     protected: true
    // },

    // {
    //     path: '/agenda',
    //     name: 'agenda',
    //     component: Agenda,
    //     protected: true
    // },

    // {
    //     path: '/locations',
    //     name: 'locations',
    //     component: Location,
    //     protected: true
    // },
    // {
    //     path: '/locations/:location_id',
    //     name: 'location',
    //     component: Location,
    //     protected: true
    // },

    // {
    //     path: '/renew',
    //     name: 'renewPlan',
    //     component: RenewPlan,
    //     protected: true
    // },



    // {
    //     path: '/payment/success',
    //     name: 'paymentSuccess',
    //     component: PaymentSuccess,
    //     protected: true
    // },
    // {
    //     path: '/payment/fail',
    //     name: 'paymentFail',
    //     component: PaymentFail,
    //     protected: true
    // },

] 

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes
})

router.beforeEach((to, from, next) => {
    // console.log('before each', to, from, next)

    let route_info = routes.filter((route) => { return route.path == to.fullPath})[0] || {};

    // console.log('protected route?', route_info, route_info.protected)

    if (route_info.protected == true) {
        if (Cookies.readCookie('access_token') != null) {
            next() //Continue;
        }else {
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            });
        }
    }

    next();
})

export default router