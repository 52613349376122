import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import axios from 'axios'

// import VueLodash from 'vue-lodash'
// import lodash from 'lodash'

// import VueGoogleMap from 'vuejs-google-maps'

import Moment from 'vue-moment'

// import VCalendar from 'v-calendar';

import VueTippy, { TippyComponent } from "vue-tippy";

import Vue2Filters from 'vue2-filters'

// import VueCurrencyFilter from 'vue-currency-filter'

Vue.prototype.$http = axios

Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);

// import ClickOutsideDirective from './directives/click-outside.js'

import jQuery from 'jquery'
global.jQuery = jQuery
global.$ = jQuery

import APIHandler from './assets/libraries/API_Requests'
global.API = APIHandler

import Spotify from 'spotify-web-api-node'
import VueSpotify from 'vue-spotify'

Vue.use(VueSpotify, new Spotify())

import MarqueeText from 'vue-marquee-text-component'
Vue.component('marquee-text', MarqueeText)
Vue.component('MarqueeText', MarqueeText)

import VueVirtualScroller from 'vue-virtual-scroller'
Vue.use(VueVirtualScroller)

Vue.config.productionTip = false

// new Vue({
//   router,
//   render: h => h(App),
// }).$mount('#app')

Vue.use(Moment);

// Vue.use(VueLodash, { name: 'test' , lodash: lodash })

// Vue.use(VueGoogleMap, {
//   load: {
//     apiKey: 'AIzaSyDqp_5RCf2Mv6ccCIx9IvG1O20weweOiVk',
//     libraries: [
//       'places',
//       'visualization'
//     ],
//   }
// })

// Vue.use(VCalendar);


Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

const store = new Vuex.Store({
    state: {
        count: 0,
        account_info: {},
        is_admin: false
    },
    mutations: {
        increment (state) {
            state.count++
        },
        loggedInUser(state, account_info) {
            state.account_info = account_info;
            state.is_admin = account_info.is_admin == true ? true : false;
        },
        loggedOutUser(state) {
            state.account_info = {}
            state.is_admin = false
        }
    },
    plugins: [vuexLocal.plugin]
})

// import store from './store.js'

new Vue({
  router,
  render: h => h(App),
  store: store,
  mixins: [Vue2Filters.mixin],
}).$mount('#app')

Vue.use(Vue2Filters)

// Vue.use(VueCurrencyFilter,
//   {
//     symbol : '$',
//     thousandsSeparator: ',',
//     fractionCount: 2,
//     fractionSeparator: '.',
//     symbolPosition: 'front',
//     symbolSpacing: false,
//     avoidEmptyDecimals: undefined,
//   })

Vue.filter('phone_number_formatter', function (phone) {
  return phone.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
});

// console.log(Vue.lodash.random(20))
// console.log(Vue._.random(20))
// console.log((Vue as any).custom.random(20))

// App.directive('click-outside', ClickOutsideDirective)



// Vue.directive('click-outside',{
//   beforeMount: function (el, binding, vnode) {
//       el.eventSetDrag = function () {
//           el.setAttribute('data-dragging', 'yes');
//       }
//       el.eventClearDrag = function () {
//           el.removeAttribute('data-dragging');
//       }
//       el.eventOnClick = function (event) {
//           var dragging = el.getAttribute('data-dragging');
//           // Check that the click was outside the el and its children, and wasn't a drag
//           if (!(el == event.target || el.contains(event.target)) && !dragging) {
//               // call method provided in attribute value
//               vnode.context[binding.expression](event);
//           }
//       };
//       document.addEventListener('touchstart', el.eventClearDrag);
//       document.addEventListener('touchmove', el.eventSetDrag);
//       document.addEventListener('click', el.eventOnClick);
//       document.addEventListener('touchend', el.eventOnClick);
//   }, unmounted: function (el) {
//       document.removeEventListener('touchstart', el.eventClearDrag);
//       document.removeEventListener('touchmove', el.eventSetDrag);
//       document.removeEventListener('click', el.eventOnClick);
//       document.removeEventListener('touchend', el.eventOnClick);
//       el.removeAttribute('data-dragging');
//   },
// });



// Vue.directive('click-outside',{
//   bind: function (el, binding, vnode) {
//       el.eventSetDrag = function () {
//           el.setAttribute('data-dragging', 'yes');
//       }
//       el.eventClearDrag = function () {
//           el.removeAttribute('data-dragging');
//       }
//       el.eventOnClick = function (event) {
//           var dragging = el.getAttribute('data-dragging');
//           // Check that the click was outside the el and its children, and wasn't a drag
//           if (!(el == event.target || el.contains(event.target)) && !dragging) {
//               // call method provided in attribute value
//               vnode.context[binding.expression](event);
//           }
//       };
//       document.addEventListener('touchstart', el.eventClearDrag);
//       document.addEventListener('touchmove', el.eventSetDrag);
//       document.addEventListener('click', el.eventOnClick);
//       document.addEventListener('touchend', el.eventOnClick);
//   }, unbind: function (el) {
//       document.removeEventListener('touchstart', el.eventClearDrag);
//       document.removeEventListener('touchmove', el.eventSetDrag);
//       document.removeEventListener('click', el.eventOnClick);
//       document.removeEventListener('touchend', el.eventOnClick);
//       el.removeAttribute('data-dragging');
//   },
// });

