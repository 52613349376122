<template>

    <section class="relative h-full" @click.stop.prevent="$emit('click', $event)">
        <div class="shadow sm:rounded-md sm:overflow-hidden h-full border-2 border-transparent" :class="{'bg-gray-50 hover:bg-gray-200 cursor-pointer': hoverable != false, 'bg-gray-200 border-gray-800 shadow-xl': selected == true}">
            
            <div v-if="info == undefined || info.track == undefined || info.track.album == undefined" class="w-full h-full flex justify-center items-center text-gray-400">
                <span>No Song Info</span>
            </div>

            <div v-else class="py-4 px-4 h-full">

                <div class="grid grid-cols-4 gap-x-2 h-full">

                    <div class="col-span-1">
                        <div class=" w-full h-full flex items-center relative" :class="{'bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 rounded-lg justify-center': info.track.album.images[0] == undefined || info.track.album.images[0].url == undefined}">
                            <img v-if="info.track.album.images[0] != undefined && info.track.album.images[0].url != undefined" :src="info.track.album.images[0].url" class="rounded-lg w-full overflow-hidden shadow-lg">
                            <svg v-else class="w-2/3 text-white shadow-lg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3" />
                            </svg>

                            <div class="flex justify-center items-center absolute bottom-0 right-0" 
                                 style="bottom: 0rem; right: -0.525rem;"
                                 :content="`Added by ${user_profiles == undefined || user_profiles[info.added_by.id] == undefined ? info.added_by.id : user_profiles[info.added_by.id].display_name}`" v-tippy>
                                <img v-if="user_profiles != undefined && user_profiles[info.added_by.id] != undefined && user_profiles[info.added_by.id].images != undefined && user_profiles[info.added_by.id].images.length > 0 && user_profiles[info.added_by.id].images[0].url != undefined" :src="user_profiles[info.added_by.id].images[0].url" class="rounded-full shadow-2xl w-5 h-5 overflow-hidden" style="box-shadow: 0px 0px 17px 3px rgb(0 0 0 / 45%);">
                                <span v-else class="rounded-full shadow-2xl w-5 h-5 overflow-hidden bg-pink-600 text-white flex justify-center items-center" style="box-shadow: 0px 0px 17px 3px rgb(0 0 0 / 45%);">{{info.added_by.id.substring(0,1).toUpperCase()}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-span-3 overflow-hidden flex flex-col">
                        <span class="truncate whitespace-none text-sm" :content="info.track.name" v-tippy>{{info.track.name}}</span>
                        <!-- <span v-if="obfuscate_song_title != true" class="truncate whitespace-none text-sm" :content="info.track.name" v-tippy>{{info.track.name}}</span> -->
                        <!-- <span v-else class="truncate whitespace-none text-sm" :content="info.track.name" v-tippy style='font-family: "minecraft enchantment";'>{{info.track.name}}</span> -->

                        <span class="truncate whitespace-none text-gray-500 text-sm font-light" :content="info.track.artists.map(artist => artist.name).join(', ')" v-tippy>{{info.track.artists.map(artist => artist.name).join(', ')}}</span>

                        <div class="w-full flex items-end justify-end space-x-2 text-gray-500 mt-1">
                            <span class="" :content="`Album: ${info.track.album.name}`" v-tippy>
                                <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                                </svg>
                            </span>

                            <span class="" :content="`Duration: ${getSongDurationFormated(info.track.duration_ms)}`" v-tippy>
                                <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </span>

                            <span class="" :content="`Added ${$moment(info.added_at).format('YYYY-MM-DD')}`" v-tippy>
                                <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                </svg>
                            </span>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    </section>

</template>

<script>

// import API from '@/assets/libraries/API_Requests'

// import LoadingIndicator from "vue-loading-overlay";
// import 'vue-loading-overlay/dist/vue-loading.css';

// import MarqueeText from 'vue-marquee-text-component'

export default {
    name: "song_item",
    components: { },
    props: ["info", "hoverable", "selected", "user_profiles", "obfuscate_song_title"],
    data() {
        return {

        }
    },
    methods: {
        getSongDurationFormated: function(ms) {
            if (ms == undefined) { return '0:00'; }
            let date = this.$moment(ms, 'x')
            return date.format('m:ss')
        },

        cleanInfoObject: function() {
            if (this.info.track == undefined) { this.info.track = { name: 'UNKNOWN', album: { name: 'UNKNOWN_ALBUM', images: [{url: undefined}]}, artists: []}; }

            else if (this.info.track.album == undefined) { this.info.track.album = { name: 'UNKNOWN_ALBUM', images: [{url: undefined}]}; }
            else if (this.info.track.album.images == undefined) { this.info.track.album.images = [{url: undefined}]; }

            else if (this.info.track.artists == undefined) { this.info.track.artists = []; }
        }
    },
    mounted() {
        window.moment = this.$moment
        // this.updateRequiredFields()
        // console.log('location: ', this.location, this.service_location_is_loading)
        this.cleanInfoObject();
    },
    watch: {
        info: function() {
            this.cleanInfoObject();
        }
        // location: function(new_value) {
        //     this.form_data = {...this.form_data, ...cloneDeep(new_value)}

            // this.location_data = {...this.location_data, ...new_value}
            // this.location_data = new_value

            // this.original_values = cloneDeep(new_value);
            // this.original_location_data = JSON.parse(JSON.stringify(new_value))
        //     this.has_been_edited = false
        //     this.updateRequiredFields();
        // }
    }
};

</script>
