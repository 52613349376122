/* eslint-disable no-unused-vars */
import $ from 'jquery'
import Cookies from './basicFunctions'

const API = {}

API.BASE_API_URL = "http://localhost:4060";
//API.BASE_API_URL = "https://wa.dawsonseibold.com/api";
// const BASE_API_URL = "https://api.cobrashowcreator.com";
// const BASE_API_URL = "ec2-18-206-180-37.compute-1.amazonaws.com";

API.Call = function(method = "POST", url, objectsToPass = {}, callbackToSendJSON, content_type = "application/json")  {
     return CallAPI(method, url, objectsToPass, callbackToSendJSON, content_type);
}

API.CallSpotify = function(method = "POST", url, objectsToPass = {}, callbackToSendJSON, content_type = "application/json")  {
     return CallSpotifyAPI(method, url, objectsToPass, callbackToSendJSON, content_type);
}

export default API

function CallAPI(method = "POST", url, objectsToPass = {}, callbackToSendJSON, content_type = "application/json") {
     if (!$.isFunction(callbackToSendJSON)) {callbackToSendJSON = function () {};}
     if (url == null || url == "") { //No Url Given
          callbackToSendJSON({
               "error": {
                    "status": 404,
                    "code": 2,
                    "title": "MISSING_URL",
                    "description": "No url was passed."
               }
          });
          return;
     }

     let timeout = 0; //No` timeout
     // try {
     //      if (is_in_maintenance_mode != undefined && is_in_maintenance_mode == true) { timeout = 7000; } //7 Second timeout while in maintenance mode
     // }catch (error) {};

     //Check if there is an internet connection;
     if (navigator.onLine || true == true) { //There is an internet connection;
          const token = Cookies.readCookie('token');
          const data = content_type == "application/json" ? JSON.stringify(objectsToPass) : objectsToPass;

          $.ajax({
               url: url,
               headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': `${content_type}`
               },
               method: method,
               data: data,
               mode: 'cors',
               timeout: timeout,
               success: function(results){
                    console.log("[API RESPONSE] Reponse:", results);
                    callbackToSendJSON(results);
               },
               error: function(error) {
                    var returnedJSON = undefined;

                    console.log('ERROR', error);

                    if (error.responseJSON == undefined || error.status == 0) { //Server is down or No Internet
                         console.warn('NO NETWORK CONNECTION FOUND', error.response, navigator.online);
                         returnedJSON = {
                              "error": {
                                   "status": 404,
                                   "code": 0,
                                   "title": "NO_NETWORK_CONNECTION",
                                   "description": "There is no internet connection."
                              }
                         }
                    }else if (error.responseJSON != undefined || null) {
                         returnedJSON = error.responseJSON;
                         console.log("[Some Error Occured in API] RAW: ",error.responseJSON);
                    }else {
                         returnedJSON = {
                              "error": {
                                   "status": 404,
                                   "code": 1,
                                   "title": "UNKNOWN_ERROR",
                                   "description": "No response was sent back from the API."
                              }
                         }
                    }
                    console.log("[Some Error Occured in API] RESPONSE BEING RETURNED:", returnedJSON);
                    callbackToSendJSON(returnedJSON);
               }
          })
     }else { //There is no internet connection; Try to find a cache else return with no internet and let the caller handle it;
          console.error("NO INTERNET CONNECTION!!!");
          callbackToSendJSON({
               "error": {
                    "status": 404,
                    "code": 0,
                    "title": "NO_NETWORK_CONNECTION",
                    "description": "There is no internet connection."
               }
          });
     }
}

function CallSpotifyAPI(method = "POST", url, objectsToPass = {}, callbackToSendJSON, content_type = "application/json") {
     if (!$.isFunction(callbackToSendJSON)) {callbackToSendJSON = function () {};}
     if (url == null || url == "") { //No Url Given
          callbackToSendJSON({
               "error": {
                    "status": 404,
                    "code": 2,
                    "title": "MISSING_URL",
                    "description": "No url was passed."
               }
          });
          return;
     }

     let timeout = 0; //No` timeout

     //Check if there is an internet connection;
     if (navigator.onLine || true == true) { //There is an internet connection;
          const token = Cookies.readCookie('access_token');
          const data = content_type == "application/json" ? JSON.stringify(objectsToPass) : objectsToPass;

          url = `https://api.spotify.com/v1${url}`

          $.ajax({
               url: url,
               headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': `${content_type}`
               },
               method: method,
               data: data,
               mode: 'cors',
               timeout: timeout,
               success: function(results){
                    console.log("[API RESPONSE] Reponse:", results);
                    callbackToSendJSON(results);
               },
               error: function(error) {
                    var returnedJSON = undefined;

                    console.log('ERROR', error);

                    if (error.responseJSON == undefined || error.status == 0) { //Server is down or No Internet
                         console.warn('NO NETWORK CONNECTION FOUND', error.response, navigator.online);
                         returnedJSON = {
                              "error": {
                                   "status": 404,
                                   "code": 0,
                                   "title": "NO_NETWORK_CONNECTION",
                                   "description": "There is no internet connection."
                              }
                         }
                    }else if (error.responseJSON != undefined || null) {
                         returnedJSON = error.responseJSON;
                         console.log("[Some Error Occured in API] RAW: ",error.responseJSON);
                    }else {
                         returnedJSON = {
                              "error": {
                                   "status": 404,
                                   "code": 1,
                                   "title": "UNKNOWN_ERROR",
                                   "description": "No response was sent back from the API."
                              }
                         }
                    }
                    console.log("[Some Error Occured in API] RESPONSE BEING RETURNED:", returnedJSON);
                    callbackToSendJSON(returnedJSON);
               }
          })
     }else { //There is no internet connection; Try to find a cache else return with no internet and let the caller handle it;
          console.error("NO INTERNET CONNECTION!!!");
          callbackToSendJSON({
               "error": {
                    "status": 404,
                    "code": 0,
                    "title": "NO_NETWORK_CONNECTION",
                    "description": "There is no internet connection."
               }
          });
     }
}