<template>
  <div id="app">
    <!-- Hide the header for certain pages such as the login using the v-if -->
    <Header v-if="hide_header_for_routes.indexOf($route.path) == -1" />

    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- {{test_message}} -->

    <v-content>
      <router-view></router-view>
    </v-content>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
// import $ from 'jquery';
// import * as $ from 'jquery';

import Header from './components/layout/Header'
// import HelloWorld from './components/HelloWorld.vue'

let routes_to_hide_header_for = Header.data().hide_header_for_routes

export default {
  name: 'App',
  components: {
    Header,
    // HelloWorld
  },
  data() {
    return {
      test_message: "what up world?",
      hide_header_for_routes: routes_to_hide_header_for  
    }
  }
}
</script>

<style>
  @import '../public/build/tailwind.css';

  .vld-overlay {
    z-index: 9 !important;
  }

</style>
