<template>
    <div class="home">
        <header class="bg-white shadow">
            <div class="max-w-full mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h1 class="text-3xl font-bold leading-tight text-gray-900">
                Home
            </h1>
            </div>
        </header>
        <main>
            <div class="max-w-full mx-auto py-6 sm:px-6 lg:px-8">
            <!-- Replace with your content -->
                <div class="px-4 py-6 sm:px-0">
                    <div class="border-4 border-dashed border-gray-200 rounded-lg h-96">
                        Homepage
                    </div>
                </div>
            <!-- /End replace -->
            </div>
        </main>
    </div>
</template>

<script>
export default {
    
}
</script>