import { openDB } from 'idb';

let DatabaseHelper = {}
DatabaseHelper.is_supported = true
DatabaseHelper.database = undefined;


(() => { //INIT
     console.log('[DB] DATABASE INIT');
     if (!('indexedDB' in window)) {
          console.log('This browser doesn\'t support IndexedDB');
          DatabaseHelper.is_supported = false
          return;
     }

     console.log('[DB] Start to init database');
     DatabaseHelper.database = openDB('song_database', 4, {
          upgrade(upgrade_database, old_version, new_version, transaction) { //Init new Database
               console.log('[DB] Initing a new object store in a new database', old_version, new_version, transaction);
               
               if (upgrade_database.objectStoreNames.contains('playlists') == false) { //Create settings table
                    // upgrade_database.createObjectStore('playlists', {keyPath: 'playlist_id'})
                    upgrade_database.createObjectStore('playlists')
               }

               if (upgrade_database.objectStoreNames.contains('user_profiles') == false) { //Create settings table
                // upgrade_database.createObjectStore('playlists', {keyPath: 'playlist_id'})
                upgrade_database.createObjectStore('user_profiles', {keyPath: 'id'})
           }
          },
          blocked() {
               console.log('[DB] Database blocked');
          },
          blocking() {
               console.log('[DB] Database blocking');
          },
          terminated() {
               console.log('[DB] Database terminated');
          }
     })
})()


DatabaseHelper._addRow = function(database_name, value_to_store) {
     console.log('[DB] Adding row to', database_name, 'with data', value_to_store);
     
     return new Promise((resolve, reject) => {
          this.database.then((database) => {
               let tx = database.transaction(database_name, 'readwrite');
               let store = tx.objectStore(database_name)
     
               store.add(value_to_store)
               return tx.complete;
          }).then(() => {
               console.log('[DB] Successfully added item to database');
               return resolve();
          }).catch((error) => {
               console.log('[DB] Failed to add item to database', error);
               return reject(error);
          })
     });
}

DatabaseHelper._getRow = function(database_name, key) {
     console.log('[DB] Getting row from', database_name, 'with key', key);

     return new Promise((resolve, reject) => {
          this.database.then(function(database) {
               var tx = database.transaction(database_name, 'readonly');
               var store = tx.objectStore(database_name);

               return store.get(key);
          }).then(function(value) {
               console.log('[DB] Successfully got item from database', value);
               return resolve(value)
          }).catch((error) => {
               console.log('[DB] Failed to get item from database', error);
               return reject(error)
          });
     })
}

DatabaseHelper._getAllRows = function(database_name) {
     console.log('[DB] Getting all rows from', database_name);

     return new Promise((resolve, reject) => {
          this.database.then(function(database) {
               var tx = database.transaction(database_name, 'readonly');
               var store = tx.objectStore(database_name);

               return store.getAll();
          }).then(function(value) {
               console.log('[DB] Successfully got all items from database', value);
               return resolve(value)
          }).catch((error) => {
               console.log('[DB] Failed to get all items from database', error);
               return reject(error)
          });
     })
}

DatabaseHelper._updateRow = function(database_name, value, key = undefined) {
     console.log('[DB] Updating row from', database_name, 'with (optional) key', key, 'with value', value);

     return new Promise((resolve, reject) => {
          this.database.then(function(database) {
               var tx = database.transaction(database_name, 'readwrite');
               var store = tx.objectStore(database_name);
               
               if (key != undefined) { store.put(value, key); }else { store.put(value); }
               return tx.complete;
          }).then(function() {
               console.log('[DB] Successfully updated item in database');
               return resolve();
          }).catch((error) => {
               console.log('[DB] Failed to update item from database', error);
               return reject(error);
          });
     })
}

DatabaseHelper._deleteRow = function(database_name, key) {
     console.log('[DB] Deleting row from', database_name, 'with key', key);

     return new Promise((resolve, reject) => {
          this.database.then(function(database) {
               var tx = database.transaction(database_name, 'readwrite');
               var store = tx.objectStore(database_name);

               store.delete(key);
               return tx.complete;
          }).then(function() {
               console.log('[DB] Successfully deleted item in database');
               return resolve();
          }).catch((error) => {
               console.log('[DB] Failed to delete item from database', error);
               return reject(error);
          });
     })
}

//MARK: Song Storage
DatabaseHelper.PlaylistManager = {}

DatabaseHelper.PlaylistManager.getAllPlaylists = function() {
     return DatabaseHelper._getAllRows('playlists')
}

DatabaseHelper.PlaylistManager.getPlaylist = function(playlist_id) {
     return new Promise((resolve, reject) => {
          DatabaseHelper._getRow('playlists', playlist_id).then((songs) => {
               return resolve(songs)
          }).catch((error) => {
               return reject(error)
          })
     });
}

DatabaseHelper.PlaylistManager.updatePlaylist = function(playlist_id, songs) {
     // eslint-disable-next-line no-async-promise-executor
     return new Promise(async (resolve, reject) => {
          if (songs == undefined || null) { 
               console.log('[Update DB Show] songs can not be undefined; To delete a playlist use the delete playlist function.')
               return reject();
          }

        //   var playlist = await DatabaseHelper._getRow('playlists', playlist_id)

        //   if (playlist != undefined) {
        //         playlist = [...playlist, ...songs]
        //   }else {
                // playlist = songs
        //   }

          DatabaseHelper._updateRow('playlists', songs, playlist_id).then(() => {
               return resolve();
          }).catch((error) => {
               return reject(error);
          })
     });
}

DatabaseHelper.PlaylistManager.deletePlaylist = function(playlist_id) {
     return new Promise((resolve, reject) => {
          DatabaseHelper._deleteRow('playlists', playlist_id).then(() => {
               return resolve()
          }).catch((error) => {
               return reject(error)
          })
     });
}

//MARK: User Profiles Storage
DatabaseHelper.ProfileManager = {}

DatabaseHelper.ProfileManager.getAllProfiles = function() {
     return DatabaseHelper._getAllRows('user_profiles')
}

DatabaseHelper.ProfileManager.getProfile = function(user_id) {
     return new Promise((resolve, reject) => {
          DatabaseHelper._getRow('user_profiles', user_id).then((songs) => {
               return resolve(songs)
          }).catch((error) => {
               return reject(error)
          })
     });
}

DatabaseHelper.ProfileManager.updateProfile = function(profile_info) {
     // eslint-disable-next-line no-async-promise-executor
     return new Promise(async (resolve, reject) => {
          if (profile_info == undefined || null) { 
               console.log('[Update DB Show] profile can not be undefined; To delete a playlist use the delete playlist function.')
               return reject();
          }

          profile_info.cached_on = new Date().getTime()

        //   var current_profile = await DatabaseHelper._getRow('user_profiles', user_id)

          DatabaseHelper._updateRow('user_profiles', profile_info).then(() => {
               return resolve();
          }).catch((error) => {
               return reject(error);
          })
     });
}

DatabaseHelper.ProfileManager.updateProfiles = function(profiles) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
         if (profiles == undefined || null) { 
              console.log('[Update DB Show] profile can not be undefined; To delete a playlist use the delete playlist function.')
              return reject();
         }

         let profile_update_promises = []

        for (const profile_info of profiles) {
            profile_info.cached_on = new Date().getTime()

            profile_update_promises.push(new Promise((update_resolve) => {
                DatabaseHelper._updateRow('user_profiles', profile_info).then(() => {
                    return update_resolve();
                }).catch((error) => {
                    return update_resolve(error);
                })
            }));
        }

        Promise.all(profile_update_promises).then(() => {
            return resolve();
        }).catch((error) => {
            return reject(error);
        })
    });
}

export default DatabaseHelper