<template>

    <section class="relative h-full" @click.stop.prevent="$emit('click', $event)">
        <div class="shadow sm:rounded-md sm:overflow-hidden h-full border-2 border-transparent" :class="{'bg-gray-50 hover:bg-gray-300 cursor-pointer': hoverable != false, 'bg-gray-200 border-gray-800 shadow-xl': selected == true}">
            <div class="py-4 px-4 h-full">

                <div class="grid grid-cols-4 gap-x-2 h-full">

                    <div class="col-span-1">
                        <div class="w-full h-full flex items-center relative" :class="{'bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 rounded-lg justify-center': info.images[0] == undefined || info.images[0].url == undefined}">
                            <img v-if="info.images[0] != undefined && info.images[0].url != undefined" :src="info.images[0].url" class="rounded-lg w-full overflow-hidden shadow-lg">
                            <svg v-else class="w-2/3 text-white shadow-lg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3" />
                            </svg>
                        </div>
                    </div>

                    <div class="col-span-3 overflow-hidden flex flex-col">
                        <span class="truncate whitespace-none" :content="info.name" v-tippy>{{info.name}}</span>
                        <span class="truncate whitespace-none text-gray-500 font-light" :content="info.description" v-tippy>{{info.description}}</span>
                    </div>

                </div>

            </div>
        </div>
    </section>

</template>

<script>

// import API from '@/assets/libraries/API_Requests'

// import LoadingIndicator from "vue-loading-overlay";
// import 'vue-loading-overlay/dist/vue-loading.css';

// import MarqueeText from 'vue-marquee-text-component'

export default {
    name: "playlist_item",
    components: { },
    props: ["info", "hoverable", "selected"],
    data() {
        return {

        }
    },
    methods: {
    },
    mounted() {
        // this.updateRequiredFields()
        // console.log('location: ', this.location, this.service_location_is_loading)
    },
    watch: {
        // location: function(new_value) {
        //     this.form_data = {...this.form_data, ...cloneDeep(new_value)}

            // this.location_data = {...this.location_data, ...new_value}
            // this.location_data = new_value

            // this.original_values = cloneDeep(new_value);
            // this.original_location_data = JSON.parse(JSON.stringify(new_value))
        //     this.has_been_edited = false
        //     this.updateRequiredFields();
        // }
    }
};

</script>
